// Remova a importação de react-native
// import { StyleSheet } from 'react-native';

// Crie um objeto simples com os estilos para a web
const styles = {
    modalBackground: {
      display: 'flex', // equivalente a flex: 1 em react-native
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'fixed',  // para ocupar toda a tela
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 999,
    },
    modalContainer: {
      width: '90%',
      maxWidth: '500px', // opcional: limitar a largura máxima
      padding: 20,
      backgroundColor: '#ffffff',
      borderRadius: 10,
      textAlign: 'center',
      position: 'relative',
      margin: '0 auto',
    },
    closeIcon: {
      position: 'absolute',
      top: 15,
      left: 15,
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 10,
      color: '#333',
      textAlign: 'center',
    },
    subtitle: {
      fontSize: 16,
      marginBottom: 15,
      color: '#555',
      textAlign: 'center',
    },
    horaContainer: {
      width: '100%',
      maxHeight: 200,
      overflowY: 'auto',
    },
    horaInputContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      marginBottom: 10,
    },
    horaInput: {
      flex: 1,
      height: 40,
      border: '1px solid #ccc',
      borderRadius: 8,
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: 16,
    },
    addHoraButton: {
      backgroundColor: '#007bff',
      width: 40,
      height: 40,
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 10,
      border: 'none',
      cursor: 'pointer',
    },
    button: {
      backgroundColor: '#007bff',
      padding: '12px 20px',
      borderRadius: 8,
      width: '80%',
      textAlign: 'center',
      marginTop: 10,
      border: 'none',
      cursor: 'pointer',
    },
    buttonText: {
      color: '#ffffff',
      fontSize: 16,
      fontWeight: 'bold',
    },
    salvarButton: {
      backgroundColor: 'green',
      padding: '12px 20px',
      borderRadius: 8,
      width: '80%',
      textAlign: 'center',
      marginTop: 10,
      border: 'none',
      cursor: 'pointer',
    },
    salvarButtonText: {
      color: '#ffffff',
      fontSize: 16,
      fontWeight: 'bold',
    },
    pickerContainer: {
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: 8,
      marginBottom: 10,
      backgroundColor: '#fff',
      justifyContent: 'center',
      paddingLeft: 10, // Garante que o texto não fique colado na borda
    },
    picker: {
      width: '100%',
      height: 50,
      color: '#000',
      border: 'none',
      outline: 'none',
      fontSize: 16,
    },
    descricaoInput: {
      width: '100%',
      height: 80,
      border: '1px solid #ccc',
      borderRadius: 8,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 8,
      fontSize: 16,
      marginBottom: 10,
    },
    uploadContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 10,
    },
    uploadButton: {
      flex: 1,
      backgroundColor: '#007bff',
      padding: '12px 10px',
      borderRadius: 8,
      textAlign: 'center',
      margin: '0 5px',
      border: 'none',
      cursor: 'pointer',
    },
    uploadButtonText: {
      color: '#ffffff',
      fontSize: 14,
      fontWeight: 'bold',
    },
  };
  
  export default styles;
  