// Corrigir o retorno do hook para expor a função corretamente
import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const BuscarSolicitacaolista = async (id_rede, id_colaborador) => {
  const apiUrl = process.env.REACT_APP_API_BASE_66;
  console.log(`Buscando solicitações para o colaborador ${id_colaborador} na rede ${id_rede}`);
  try {
    const response = await api.get(`${apiUrl}${id_rede}/${id_colaborador}`);
    
    // Log da resposta completa da API
    console.log('Resposta da API:', response);
    
    if (response.status === 200) {
      console.log('Solicitações encontradas com sucesso!');
      return response;
    } else {
      console.error('ERRO: Falha ao buscar Registros de solicitação. Status:', response.status);
      return null;
    }
  } catch (error) {
    console.error('Erro ao tentar buscar as solicitações:', error);
    throw error; // Propaga o erro para tratamento externo
  }
};

export default BuscarSolicitacaolista;
