// DrawerMenu.js
import React from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { MdLogout, MdHome } from 'react-icons/md'; // Ícones de sair e de início

// Ícones dos cards
import Estabelecimento from '../../assets/Estabelecimento.png';
import Cliente from '../../assets/Cliente.png';
import Colaborador from '../../assets/Colaborador.png';
import Produto from '../../assets/Produto.png';
import Visita from '../../assets/Visitas.png';
import CockPit from '../../assets/CockPit.png';
import secureStorage from '../../utils/SecureStorage';

function DrawerMenu({ closeDrawer }) {
  const navigate = useNavigate();

  // Função para fazer logout
  const handleLogout = () => {
    secureStorage.clear();
    navigate('/');
    closeDrawer(); // Fechar o menu após sair
  };

  return (
    <S.DrawerOverlay onClick={closeDrawer}>
      <S.DrawerContainer onClick={(e) => e.stopPropagation()}>
        <S.DrawerHeader>
          <h2><FormattedMessage id="drawer.title" defaultMessage="Menu" /></h2>
        </S.DrawerHeader>
        <S.DrawerContent>
          {/* Botão "INÍCIO" adicionado ao menu */}
          <button type="button" onClick={() => { navigate('/home'); closeDrawer(); }}>
            <S.HomeCard>
              <MdHome size={24} color="#cf0505" style={{ marginRight: '10px' }} /> {/* Ícone de Home */}
              <span>
                <FormattedMessage id="home.inicio" defaultMessage="INÍCIO" />
              </span>
            </S.HomeCard>
          </button>
          {secureStorage.getItem("isVisibleEstabelecimento") && (
            <button type="button" onClick={() => { navigate('/Estabelecimento'); closeDrawer(); }}>
              <S.HomeCard>
                <img src={Estabelecimento} alt="Estabelecimentos" />
                <span>
                  <FormattedMessage id="home.estabelecimentos" defaultMessage="Estabelecimentos" />
                </span>
              </S.HomeCard>
            </button>
          )}
          {secureStorage.getItem("isVisibleCliente") && (
            <button type="button" onClick={() => { navigate('/Cliente'); closeDrawer(); }}>
              <S.HomeCard>
                <img src={Cliente} alt="Clientes" />
                <span>
                  <FormattedMessage id="home.clientes" defaultMessage="Clientes" />
                </span>
              </S.HomeCard>
            </button>
          )}
          {secureStorage.getItem("isVisibleColaborador") && (
            <button type="button" onClick={() => { navigate('/colaborador'); closeDrawer(); }}>
              <S.HomeCard>
                <img src={Colaborador} alt="Colaboradores" />
                <span>
                  <FormattedMessage id="home.colaboradores" defaultMessage="Colaboradores" />
                </span>
              </S.HomeCard>
            </button>
          )}
          {secureStorage.getItem("isVisibleProduto") && (
            <button type="button" onClick={() => { navigate('/produto'); closeDrawer(); }}>
              <S.HomeCard>
                <img src={Produto} alt="Produtos" />
                <span>
                  <FormattedMessage id="home.produtos" defaultMessage="Produtos" />
                </span>
              </S.HomeCard>
            </button>
          )}

          {secureStorage.getItem("isVisiblePonto") && (
            <button type="button" onClick={() => { navigate('/AcompanhamentoPonto'); closeDrawer(); }}>
              <S.HomeCard>
                <img src={Visita} alt="Registro Ponto" />
                <span>
                  <FormattedMessage id="home.AcompanhamentoPonto" defaultMessage="Acompanhamento Ponto" />
                </span>
              </S.HomeCard>
            </button>
          )}
          {secureStorage.getItem("isVisiblePonto") && (
            <button type="button" onClick={() => { navigate('/Ponto'); closeDrawer(); }}>
              <S.HomeCard>
                <img src={Visita} alt="Registro Ponto" />
                <span>
                  <FormattedMessage id="home.Registro_Ponto" defaultMessage="Registro Ponto" />
                </span>
              </S.HomeCard>
            </button>
          )}
          {secureStorage.getItem("isVisiblegestaoponto") && (
            <button type="button" onClick={() => { navigate('/GestaoPonto'); closeDrawer(); }}>
              <S.HomeCard>
                <img src={Visita} alt="Registro Ponto" />
                <span>
                  <FormattedMessage id="home.GestaoPonto" defaultMessage="Gestão Ponto" />
                </span>
              </S.HomeCard>
            </button>
          )}
          {secureStorage.getItem("isVisibleVisita") && (
            <button type="button" onClick={() => { navigate('/Atendimento'); closeDrawer(); }}>
              <S.HomeCard>
                <img src={Visita} alt="Agendar Atendimentos" />
                <span>
                  <FormattedMessage id="home.agendar_visitas" defaultMessage="Agendar Atendimentos" />
                </span>
              </S.HomeCard>
            </button>
          )}
          {secureStorage.getItem("isVisibleCockpit") && (
            <button type="button" onClick={() => { navigate('/Quadrodeatendimentos'); closeDrawer(); }}>
              <S.HomeCard>
                <img src={CockPit} alt="CockPit" />
                <span>
                  <FormattedMessage id="home.cockpit" defaultMessage="CockPit" />
                </span>
              </S.HomeCard>
            </button>
          )}
          {secureStorage.getItem("isVisibleRelatorio") && (
            <button type="button" onClick={() => { navigate('/Relatorio'); closeDrawer(); }}>
              <S.HomeCard>
                <img src={CockPit} alt="Relatorios" />
                <span>
                  <FormattedMessage id="home.Relatorios" defaultMessage="Relatorios" />
                </span>
              </S.HomeCard>
            </button>
          )}
          {/* Card de Sair */}
          <button type="button" onClick={handleLogout}>
            <S.HomeCard>
              <MdLogout size={24} color="#cf0505" style={{ marginRight: '10px' }} />
              <span>
                <FormattedMessage id="home.logout" defaultMessage="Sair" />
              </span>
            </S.HomeCard>
          </button>
        </S.DrawerContent>
      </S.DrawerContainer>
    </S.DrawerOverlay>
  );
}

export default DrawerMenu;
