import React, { Component } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header_login';
import Footer_login from '../../components/Footer_login';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #f9f9f9;
  font-family: 'Arial', sans-serif;
`;

const HeaderContainer = styled.div`
  width: 100%;
  background-color: #0044cc;
  padding: 10px 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const Content = styled.div`
  max-width: 900px;
  background: white;
  padding: 30px;
  margin: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: justify;
  
  h1 {
    text-align: center;
    font-size: 28px;
    color: #333;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 22px;
    color: #0044cc;
    margin-top: 20px;
  }

  h3 {
    font-size: 18px;
    color: #007bff;
    margin-top: 15px;
  }

  p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }

  ul {
    padding-left: 20px;
  }

  ul li {
    font-size: 16px;
    color: #444;
    margin: 8px 0;
    list-style: none;
    position: relative;
  }

  ul li::before {
    content: "✔️";  
    color: #007bff;
    font-weight: bold;
    margin-right: 8px;
  }

  strong {
    color: #222;
    font-weight: bold;
  }
`;

class PoliticadePrivacidade extends Component {
  render() {
    return (
      <PageContainer>
        <HeaderContainer>
          <Header />
        </HeaderContainer>

        <Content>
          <h1>Política de Privacidade</h1>
          <p><strong>Última atualização:</strong> 02 de Abril de 2025</p>

          <h2>1. Introdução</h2>
          <p>
            Bem-vindo à <strong>TRIAD SOLUCOES E TECNOLOGIA</strong>! Esta Política de Privacidade explica como coletamos, usamos e protegemos suas informações pessoais. Ao utilizar nossos serviços, você concorda com as práticas descritas nesta política.
          </p>

          <h2>2. Informações que Coletamos</h2>
          <h3>2.1 Informações fornecidas diretamente</h3>
          <ul>
            <li><strong>Dados de Cadastro:</strong> Nome, e-mail, telefone, endereço e outras informações necessárias.</li>
            <li><strong>Mensagens e Feedback:</strong> Comentários, dúvidas e interações em nosso site.</li>
          </ul>

          <h3>2.2 Informações coletadas automaticamente</h3>
          <ul>
            <li><strong>Dados de Navegação:</strong> IP, tipo de navegador, páginas visitadas e tempo de acesso.</li>
            <li><strong>Cookies e Tecnologias:</strong> Usamos cookies para personalizar sua experiência e analisar acessos.</li>
            <li><strong>Localização (GPS ou rede):</strong> Coletamos dados de localização com o seu consentimento para validar a presença em locais específicos (como ponto eletrônico ou visitas técnicas) e melhorar os serviços com base na sua posição geográfica.</li>
          </ul>

          <h2>3. Uso das Informações</h2>
          <p>Utilizamos suas informações para:</p>
          <ul>
            <li>Fornecer e melhorar nossos serviços;</li>
            <li>Gerenciar sua conta e responder a solicitações;</li>
            <li>Enviar comunicações importantes e promoções (se autorizado);</li>
            <li>Melhorar a experiência de navegação no site.</li>
          </ul>

          <h2>3.1 Uso de Dados de Localização</h2>
          <p>
            Nosso aplicativo pode coletar dados de localização aproximada ou precisa do seu dispositivo. Essa coleta é feita somente mediante autorização prévia do usuário, através das permissões do sistema operacional (Android).
          </p>
          <p>
            Os dados de localização são utilizados para:
          </p>
          <ul>
            <li>Validar o registro de ponto em locais específicos autorizados;</li>
            <li>Oferecer funcionalidades baseadas em geolocalização;</li>
            <li>Melhorar a precisão e segurança dos serviços prestados.</li>
          </ul>
          <p>
            A qualquer momento, você pode desativar a coleta de localização nas configurações do seu dispositivo, mas isso pode limitar o funcionamento de algumas funcionalidades do aplicativo.
          </p>

          <h2>4. Compartilhamento de Informações</h2>
          <p>
            <strong>Nunca vendemos suas informações.</strong> Seus dados podem ser compartilhados apenas com:
          </p>
          <ul>
            <li><strong>Prestadores de Serviço:</strong> Empresas que nos ajudam a operar o site, sob confidencialidade.</li>
            <li><strong>Autoridades Legais:</strong> Quando exigido por lei ou para proteger nossos direitos.</li>
          </ul>

          <h2>5. Segurança</h2>
          <p>
            Protegemos seus dados com medidas de segurança avançadas. No entanto, nenhuma transmissão na internet é 100% segura, então sempre utilize senhas fortes.
          </p>

          <h2>6. Cookies e Configurações</h2>
          <p>
            Você pode gerenciar cookies nas configurações do seu navegador. A desativação pode impactar algumas funções do site.
          </p>

          <h2>7. Seus Direitos</h2>
          <p>Você pode:</p>
          <ul>
            <li>Acessar os dados que temos sobre você;</li>
            <li>Solicitar correção ou exclusão de informações;</li>
            <li>Revogar consentimentos para uso de dados.</li>
          </ul>
          <p>Para exercer esses direitos, entre em contato conosco.</p>

          <h2>8. Alterações na Política</h2>
          <p>
            Podemos atualizar esta política periodicamente. Notificaremos sobre mudanças significativas.
          </p>

          <h2>9. Contato</h2>
          <p>Se tiver dúvidas, entre em contato:</p>
          <ul>
            <li><strong>E-mail:</strong> triadsolucoesetecnologia@gmail.com</li>
            <li><strong>Telefone:</strong> (45) 99931-5313</li>
            <li><strong>Endereço:</strong> Rua IRACI VIGANO, 107 - CASCAVEL-PR</li>
          </ul>
        </Content>

        <Footer_login />
      </PageContainer>
    );
  }
}

export default PoliticadePrivacidade;
