// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga';
import Login from './views/Login';
import Home from './views/Home';
import Cliente from './views/Cliente';
import Colaborador from './views/Colaborador';
import Estabelecimento from './views/Estabelecimento';
import Produto from './views/Produto';
import Atendimento from './views/Atendimento';
import Quadrodeatendimentos from './views/Quadrodeatendimentos';
import Visualizar from './views/Visualizar';
import Relatorio from './views/Relatorio';
import GestaoPonto from './views/GestaoPonto';
import Ponto from './views/Ponto';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import Sobre from './views/Sobre';
import Planos from './views/planos';
import FaleConosco from './views/faleconosco';
import EspelhoPonto from './views/EspelhoPonto';
import RelatorioPonto from './views/RelatorioPonto';
import Marketdo from './views/Marketdo';
import Rede from './views/Rede';
import Modulos from './views/Modulos';
import CNPJsRede from './views/CNPJsRede';
import Licenca from './views/Licenca';
import RotinaAtendimento from './views/RotinaAtendimento';
import RotinaPonto from './views/RotinaPonto';
import AtendimentoBase from './views/AtendimentoBase';
import AcompanhamentoPonto from './views/AcompanhamentoPonto';
import PoliticadePrivacidade from './views/PoliticadePrivacidade';

function App() {
  useEffect(() => {
    ReactGA.initialize('G-BQT8PW41ZX');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send("pageview");
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/Login" element={<Login />} />
          <Route path="/" element={<Sobre />} />
          <Route path="/faleconosco" element={<FaleConosco />} />
          <Route path="/PoliticadePrivacidadev2" element={<PoliticadePrivacidade />} />
          <Route path="/planos" element={<Planos />} />
          <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
          <Route path="/cliente" element={<ProtectedRoute element={<Cliente />} />} />
          <Route path="/colaborador" element={<ProtectedRoute element={<Colaborador />} />} />
          <Route path="/estabelecimento" element={<ProtectedRoute element={<Estabelecimento />} />} />
          <Route path="/produto" element={<ProtectedRoute element={<Produto />} />} />
          <Route path="/GestaoPonto" element={<ProtectedRoute element={<GestaoPonto/>} />} />
          <Route path="/Ponto" element={<ProtectedRoute element={<Ponto/>} />} />
          <Route path="/Relatorio" element={<ProtectedRoute element={<Relatorio />} />} />
          <Route path="/Atendimento/:id" element={<ProtectedRoute element={<Visualizar />} />} />
          <Route path="/Atendimento" element={<ProtectedRoute element={<Atendimento mode="create" />} />} />
          <Route path="/Quadrodeatendimentos" element={<ProtectedRoute element={<Quadrodeatendimentos />} />} />
          <Route path="/Atendimento/:id/editar" element={<ProtectedRoute element={<Atendimento mode="edit" />} />} />
          <Route path="/EspelhoPonto" element={<ProtectedRoute element={<EspelhoPonto />} />} />
          <Route path="/RelatorioPonto" element={<ProtectedRoute element={<RelatorioPonto />} />} />
          <Route path="/Marketdo" element={<ProtectedRoute element={<Marketdo />} />} />
          <Route path="/Rede" element={<ProtectedRoute element={<Rede />} />} />
          <Route path="/Modulos" element={<ProtectedRoute element={<Modulos />} />} />
          <Route path="/CNPJsRede" element={<ProtectedRoute element={<CNPJsRede />} />} />
          <Route path="/Licenca" element={<ProtectedRoute element={<Licenca />} />} />
          <Route path="/RotinaAtendimento" element={<ProtectedRoute element={<RotinaAtendimento />} />} />
          <Route path="/RotinaPonto" element={<ProtectedRoute element={<RotinaPonto />} />} />
          <Route path="/AtendimentoBase" element={<ProtectedRoute element={<AtendimentoBase />} />} />
          <Route path="/AcompanhamentoPonto" element={<ProtectedRoute element={<AcompanhamentoPonto />} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
