import React from 'react';
import * as XLSX from 'xlsx';

const GeraExcelPonto = ({ colaboradorAtivo }) => {
    if (!colaboradorAtivo || !colaboradorAtivo.registros) return null;

    const resumo = [
        ['Nome:', colaboradorAtivo.colaborador || 'undefined'],
        ['Total de horas:', colaboradorAtivo.total_horas_trabalhadas || '00:00'],
        ['Horas Extras:', colaboradorAtivo.total_horas_extras || '00:00'],
        ['Atraso:', colaboradorAtivo.total_atrasos || '00:00'],
        ['Banco Horas:', colaboradorAtivo.saldo_banco_horas || '00:00'],
        []
    ];

    const maxBatidas = colaboradorAtivo.registros.reduce(
        (max, reg) => Math.max(max, reg.batidas.length),
        0
    );

    const pontoHeaders = [
        'Data', 'Dia da Semana',
        ...Array.from({ length: maxBatidas }, (_, i) => `${i + 1}º Registro`),
        'Total de Horas', 'Horas Extras', 'Atraso'
    ];

    const data = colaboradorAtivo.registros.map((registro) => {
        const dataFormatada = formatData(registro.data);
        const diaSemana = getDiaDaSemana(registro.data);

        let batidas = [];

        if (registro.Feriado) {
            batidas = [registro.observacaoFeriado || 'Feriado'];
        } else if (registro.Ferias) {
            batidas = ['Férias'];
        } else if (registro.Atestado) {
            batidas = [registro.observacaoAtestado || 'Atestado'];
        } else if (registro.Falta) {
            batidas = [registro.observacaoFalta || 'Falta'];    
        } else if (registro.Semocorencia) {
            batidas = ['Sem Ocorrência'];
        } else {
            batidas = registro.batidas.map((batida) =>
                new Date(batida.timestamp).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                })
            );
        }

        const batidasCompletas = [...batidas, ...Array(maxBatidas - batidas.length).fill('Sem registro')];

        return [
            dataFormatada,
            diaSemana,
            ...batidasCompletas,
            registro.Total_de_Horas || '00:00',
            registro.Hora_Extra || '00:00',
            registro.Atraso || '00:00'
        ];
    });

    const exportToExcel = () => {
        const wsData = [...resumo, pontoHeaders, ...data];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Ponto');

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const fileName = `${colaboradorAtivo.colaborador || 'colaborador'}_Ponto_${year}-${month}.xlsx`;

        XLSX.writeFile(wb, fileName);
    };

    return (
        <button onClick={exportToExcel}>Exportar Excel</button>
    );
};

const getDiaDaSemana = (data) => {
    const diasDaSemana = [
        'Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira',
        'Quinta-feira', 'Sexta-feira', 'Sábado'
    ];
    const date = new Date(data + 'T00:00:00');
    return diasDaSemana[date.getDay()] || '-';
};

const formatData = (data) => {
    const date = new Date(data + 'T00:00:00');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

export default GeraExcelPonto;
