import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Garante que o container ocupe toda a altura da tela */
  width: 100%;
`;


export const BackButton = styled.button`
    position: absolute;
    top: 100px;
    left: 20px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #cf0505;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #800000;
    }
`;       

export const Title = styled.div`
  width: 100%;
  text-align: center;
  margin: 65px 0 20px 0; /* Aumenta a margem superior (40px) */
  
  h3 {
    color: #20295f;
    font-size: 24px;
  }
`;


export const FilterArea = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  .input {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .apply-button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .apply-button:hover {
    background-color: #45a049;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-bottom: 85px; /* Espaço extra no final para afastar o último card */
`;


export const VisitaCard = styled.div`
  width: 300px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  ul {
    margin-top: 10px;
    padding-left: 20px;
  }

  li {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }

  img {
    display: block;
    margin: 10px auto;
  }
`;

export const VisitaCardTitle = styled.h4`
  margin: 0;
  font-size: 18px;
  color: #20295f;
  text-align: center;
`;

export const VisitaCardParagraph = styled.p`
  margin: 10px 0;
  font-size: 14px;
  color: #333;
  text-align: center;
`;
