import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const BuscarSolicitacaoAprovador = async (id_rede, id_aprovador) => {
    const apiUrl = process.env.REACT_APP_API_BASE_67;
  
    try {
      const response = await api.get(`${apiUrl}${id_rede}/${id_aprovador}`);
      console.log("Solicitações recebidas:", response.data);
  
      if (response.status === 200) {
        return { status: response.status, data: response.data };
      } else {
        throw new Error(`Erro: Status ${response.status}`);
      }
    } catch (error) {
      console.error("Erro ao buscar solicitações:", error);
      throw error;
    }
  };
  

export default BuscarSolicitacaoAprovador;
