import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CabecarioIcon from '../../components/CabecarioIcon';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FaInfoCircle, FaEye, FaEyeSlash } from 'react-icons/fa';
import ViaCepService from '../../services/Viacep/ViaCepService';
import CadastroGeral from '../../services/Colaborador/CadastroGeral';
import useBuscaClolaborador from '../../services/Colaborador/useBuscaClolaborador';
import useBuscaPerfil from '../../services/Colaborador/useBuscaPerfil';
import BuscaPerfil from '../../services/Colaborador/BuscaPerfil';
import useBucaUser from '../../services/Colaborador/useBucaUser';
import BuscaEmpreCnpj from '../../services/Colaborador/BuscaEmpreCnpj';
import deleteUser from '../../services/Colaborador/deletar';
import { MascaraCpfCnpj, ValidaCpf, maskDate, ValidaCnpj, MascaraCep, MascaraTelefone, MascaraTelefoneFixo, ValidaEmail, MascaraRg } from '../../utils/mask';
import secureStorage from '../../utils/SecureStorage';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import ExibirWiki from '../../components/ExibirWiki/ExibirWiki';
import { FaQuestionCircle } from 'react-icons/fa';

function Colaborador() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { searchResults, loadregistros } = useBuscaClolaborador([]);
  const { searchResults2, loadregistros2 } = useBuscaPerfil([]);
  const { searchResults3, loadregistros3 } = useBucaUser([]);
  const { searchResults4, loadregistros4 } = BuscaEmpreCnpj([]);
  const [nome, setNome] = useState('');
  const [erro, setErro] = useState(null);
  const [idcolaborador, setIdcolaborador] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const intl = useIntl();
  const [registros, setRegistros] = useState([]);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [mostrarAjuda, setMostrarAjuda] = useState(false);
  const contentLabel = intl.formatMessage({ id: 'form.buscar_cadastros', defaultMessage: 'Buscar Cadastros' });
  const [colaborador, setColaborador] = useState({
    _id: '',
    id_rede: secureStorage.getItem("id_r"),
    cpf_cnpj: '',
    rg: '',
    data_nascimento: '',
    nome: '',
    nome_social: '',
    sobrenome: '',
    Sexo: '',
    Genero: '',
    blocked: false,
    inactive: false,
    endereco: {
      rua: '',
      numero: '',
      complemento: '',
      bairro: '',
      cep: '',
      cidade: '',
      estado: ''
    },
    contato: {
      telefone_fixo: '',
      celular: '',
      email: '',
      WhatsApp: '',
      observacoes: ''
    },
    dadosprofissionais: {
      cargo: '',
      departamento: '',
      data_admissao: '',
      numero_registro: ''
    }
  });

  const [perfil, setPerfil] = useState({
    _id: '',
    id_rede: secureStorage.getItem("id_r"),
    id_ligacao: '',
    nomeUser: '',
    empresa: '',
    blocked: false,
    inactive: false,
    isVisibleCliente: false,
    TipoCliente: 0,
    isVisibleColaborador: false,
    TipoColaborador: 0,
    isVisibleVisita: false,
    TipoVisita: 0,
    isVisibleEstabelecimento: false,
    TipoEstabelecimento: 0,
    isVisibleCockpit: false,
    TipoCockpit: 0,
    isVisibleProduto: false,
    TipoProduto: 0,
    isVisibleRelatorio: false,
    TipoRelatorio: 0,
    isVisibleProduto_app: false,
    TipoPonto: 0,
    isVisiblePonto: false,
    isVisiblegestaoponto: false,
    ponto: { // Certifique-se de que esta propriedade está sempre presente
      Aprovador: [],
      CargaHoraria: {
        SEGUNDA: '',
        TERCA: '',
        QUARTA: '',
        QUINTA: '',
        SEXTA: '',
        SABADO: '',
        DOMINGO: '',
        FERIADOS: '',
      }
    }
  });

  const [user, setUser] = useState({
    _id: '',
    id_unico: '',
    username: '',
    password: '',
    usuario_app: '',
    id_r: secureStorage.getItem("id_r"),
    id_l: '',
    tipo_app: '',
    blocked: false,
    inactive: false
  });

  const [visibleModules, setVisibleModules] = useState({
    cliente: false,
    colaborador: false,
    atendimentos: false,
    estabelecimento: false,
    cockpit: false,
    produto: false,
    ponto: false,
    relatorio: false,
  });

  const toggleModule = (moduleName) => {
    setVisibleModules((prev) => ({
      ...prev,
      [moduleName]: !prev[moduleName],
    }));
  };

  const [showPassword, setShowPassword] = useState(false);

  const [opcoes, setOpcoes] = useState([]); // Inicializa como array vazio
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onBlurCep = (e) => {
    let { value } = e.target;
    const cep = value.replace(/\D/g, '');

    if (cep.length !== 8) {
      return;
    }

    ViaCepService.fetchAddress(cep)
      .then((data) => {
        if (data.erro) {
          alert('CEP não encontrado.');
          return;
        }
        setColaborador((prevState) => ({
          ...prevState,
          endereco: {
            ...prevState.endereco,
            rua: data.logradouro,
            bairro: data.bairro,
            cidade: data.localidade,
            estado: data.uf,
            cep: value
          }
        }));
      })
      .catch((error) => {
        alert('Erro ao buscar CEP: ' + error.message);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cpf_cnpj = MascaraCpfCnpj(colaborador.cpf_cnpj);

    if (!colaborador.nome) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_nome', defaultMessage: 'Você precisa informar o nome' }));
      moveFocus('nome');
      return;
    }

    if (!colaborador.sobrenome) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_sobrenome', defaultMessage: 'Você precisa informar o sobrenome' }));
      return;
    }


    if (!ValidaCpf(cpf_cnpj) && !ValidaCnpj(cpf_cnpj)) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_cpf_cnpj', defaultMessage: 'Você precisa informar o CPF/CNPJ inválido' }));
      return;
    }

    if (!colaborador.data_nascimento) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_data_nascimento', defaultMessage: 'Você precisa informar a data_nascimento' }));
      return;
    }

    // // Verifica se o CEP do endereço foi informado
    // if (!colaborador.endereco.cep) {
    //   setErro(intl.formatMessage({ id: 'form.error.invalid_cep', defaultMessage: 'Você precisa informar o CEP do Endereço' }));
    //   return;
    // }

    // // Verifica se a rua do endereço foi informada
    // if (!colaborador.endereco.rua) {
    //   setErro(intl.formatMessage({ id: 'form.error.invalid_rua', defaultMessage: 'Você precisa informar a Rua do Endereço' }));
    //   return;
    // }

    // // Verifica se o número do endereço foi informado
    // if (!colaborador.endereco.numero) {
    //   setErro(intl.formatMessage({ id: 'form.error.invalid_numero', defaultMessage: 'Você precisa informar o Número do Endereço' }));
    //   return;
    // }

    // // Verifica se o bairro do endereço foi informado
    // if (!colaborador.endereco.bairro) {
    //   setErro(intl.formatMessage({ id: 'form.error.invalid_bairro', defaultMessage: 'Você precisa informar o Bairro do Endereço' }));
    //   return;
    // }

    // // Verifica se a cidade do endereço foi informada
    // if (!colaborador.endereco.cidade) {
    //   setErro(intl.formatMessage({ id: 'form.error.invalid_cidade', defaultMessage: 'Você precisa informar a Cidade do Endereço' }));
    //   return;
    // }

    // // Verifica se o estado do endereço foi informado
    // if (!colaborador.endereco.estado) {
    //   setErro(intl.formatMessage({ id: 'form.error.invalid_estado', defaultMessage: 'Você precisa informar o Estado do Endereço' }));
    //   return;
    // }

    if (!ValidaEmail(colaborador.contato.email)) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_email', defaultMessage: 'Você precisa informar um Email válido' }));
      return;
    }

    if (!perfil.nomeUser) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_nomeUser', defaultMessage: 'Você precisa informar o Nome do Usuário' }));
      return;
    }

    if (!user.password) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_password', defaultMessage: 'Você precisa informar informar o password' }));
      return;
    }

    if (!user.username) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_username', defaultMessage: 'Você precisa informar informar o username' }));
      return;
    }

    if (!user.tipo_app) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_tipo_app', defaultMessage: 'Você precisa informar informar o tipo_app' }));
      return;
    }


    try {
      if (!perfil.id_rede) {
        setPerfil({ ...perfil, id_rede: secureStorage.getItem("id_r") });
      }

      if (!user.id_r) {
        setUser({ ...user, id_r: secureStorage.getItem("id_r") });
      }

      if (!colaborador.id_rede) {
        setColaborador({ ...colaborador, id_rede: secureStorage.getItem("id_r") });
      }


      const dados = { colaborador, perfil, user };

      await CadastroGeral.salvarTudo(dados);
      handleResetForm();
      setTabIndex(0);
    } catch (error) {
      console.error("Erro ao salvar o colaborador:", error);
      setErro(intl.formatMessage({ id: 'form.error.saving', defaultMessage: 'Erro ao salvar o cadastro' }));
    }
  };

  const handleOpenModal = async () => {
    handleResetForm();
    setIsModalOpen(true);
    const idrede = secureStorage.getItem("id_r");
    if (idrede) {
      await loadregistros(idrede);
    }
  };



  const handleSearchChange = (event, setSearchTerm) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectcolaborador = (colaboradorSelecionado) => {
    handleResetForm();
    secureStorage.setItem("id_colab", colaboradorSelecionado._id.$oid);
    setIdcolaborador(colaboradorSelecionado._id.$oid);

    // Inicializa `ponto` e `CargaHoraria` caso estejam `undefined`
    const colaboradorFormatado = {
      ...colaboradorSelecionado,
      ponto: {
        ...(colaboradorSelecionado.ponto || { // Garante que `ponto` seja um objeto
          CargaHoraria: {} // Inicializa `CargaHoraria` se estiver `undefined`
        }),
        CargaHoraria: {
          SEGUNDA: colaboradorSelecionado.ponto?.CargaHoraria?.SEGUNDA || '',
          TERCA: colaboradorSelecionado.ponto?.CargaHoraria?.TERCA || '',
          QUARTA: colaboradorSelecionado.ponto?.CargaHoraria?.QUARTA || '',
          QUINTA: colaboradorSelecionado.ponto?.CargaHoraria?.QUINTA || '',
          SEXTA: colaboradorSelecionado.ponto?.CargaHoraria?.SEXTA || '',
          SABADO: colaboradorSelecionado.ponto?.CargaHoraria?.SABADO || '',
          DOMINGO: colaboradorSelecionado.ponto?.CargaHoraria?.DOMINGO || '',
          FERIADOS: colaboradorSelecionado.ponto?.CargaHoraria?.FERIADOS || '',
        },
      },
    };




    // Formata a data de nascimento
    if (colaboradorFormatado.data_nascimento && colaboradorFormatado.data_nascimento.$date) {
      colaboradorFormatado.data_nascimento = new Date(colaboradorFormatado.data_nascimento.$date).toLocaleDateString('pt-BR');
    }

    // Formata a data de admissão
    if (colaboradorFormatado.dadosprofissionais && colaboradorFormatado.dadosprofissionais.data_admissao && colaboradorFormatado.dadosprofissionais.data_admissao.$date) {
      colaboradorFormatado.dadosprofissionais.data_admissao = new Date(colaboradorFormatado.dadosprofissionais.data_admissao.$date).toLocaleDateString('pt-BR');
    }

    handleResetForm();
    setColaborador(colaboradorFormatado);
    handleCloseModal();
    loadregistrosPerfil();
    setErro('');
  };

  useEffect(() => {
    const carregarOpcoes = async () => {
      try {
        setLoading(true);
        const dados = await BuscaPerfil(); // Chama o serviço para buscar os dados
        setOpcoes(dados); // Atualiza o estado com os dados
      } catch (erro) {
        setError("Erro ao carregar os dados");
        console.error(erro);
      } finally {
        setLoading(false);
      }
    };

    carregarOpcoes();
  }, []);

  const loadregistrosPerfil = async () => {
    const idrede = secureStorage.getItem("id_r");
    const idLigacao = secureStorage.getItem("id_colab");
    if (idrede && idLigacao) {
      const error = await loadregistros2(idrede, idLigacao);
      if (error) {
        console.error("Erro ao carregar registros:", error);
      } else if (secureStorage.getItem("dataPrfil")) {
        let dataPrfil = secureStorage.getItem("dataPrfil");
        if (dataPrfil) {
          dataPrfil = JSON.parse(dataPrfil);
          setPerfil(dataPrfil);
          secureStorage.setItem("id_Prfil", dataPrfil._id.$oid);
          loadregistrosUser();
        }
      }
    }
  };

  const loadregistrosUser = async () => {
    const idL = secureStorage.getItem("id_Prfil");
    if (idL) {
      const error = await loadregistros3(idL);
      if (error) {
        console.error("Erro ao carregar registros:", error);
      } else if (secureStorage.getItem("dataUser")) {
        let dataUser = secureStorage.getItem("dataUser");
        if (dataUser) {
          dataUser = JSON.parse(dataUser);
          setUser(dataUser);
        }
        secureStorage.setItem("dataPrfil", '');
      }
    }
  };


  const loadregistrosredecnpj = async () => {
    const id_rede = secureStorage.getItem("id_r");
    if (id_rede) {
      const error = await loadregistros4(id_rede);
      if (error) {
        console.error("Erro ao carregar registros:", error);
      } else if (secureStorage.getItem("dataredecnpj")) {
        let dataUser = secureStorage.getItem("dataredecnpj");
        if (dataUser) {
          dataUser = JSON.parse(dataUser);
          return dataUser.length ? dataUser : null; // Retorna dataUser ou null se lista vazia
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await loadregistrosredecnpj();
      if (data && data.length) {
        // Garantir que o estado `perfil` sempre tenha `ponto.CargaHoraria` inicializado corretamente
        const updatedData = data.map((item) => ({
          ...item,
          ponto: item.ponto || { // Se `ponto` não existir, inicialize com uma estrutura padrão
            CargaHoraria: {
              SEGUNDA: '',
              TERCA: '',
              QUARTA: '',
              QUINTA: '',
              SEXTA: '',
              SABADO: '',
              DOMINGO: '',
              FERIADOS: '',
            }
          }
        }));
        setRegistros(updatedData);
      }
    };
    fetchData();
  }, []); // Executa apenas quando o componente for montado


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case 'contato.celular':
      case 'contato.WhatsApp':
        value = MascaraTelefone(value);
        break;
      case 'contato.telefone_fixo':
        value = MascaraTelefoneFixo(value);
        break;
      case 'endereco.cep':
        value = MascaraCep(value);
        break;
      case 'cpf_cnpj':
        value = MascaraCpfCnpj(value);
        break;
      case 'rg':
        value = MascaraRg(value);
        break;
      case 'data_nascimento':
        value = maskDate(value);
        break;
      case 'dadosprofissionais.data_admissao':
        value = maskDate(value);
        break;
      default:
        break;
    }

    if (name.includes('.')) {
      const [parentKey, childKey] = name.split('.');
      setColaborador(prevState => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: value
        }
      }));
    } else {
      setColaborador(prevState => ({
        ...prevState,
        [name]: value
      }));
    }




    // Verifique se é um campo de Carga Horária
    if (["SEGUNDA", "TERCA", "QUARTA", "QUINTA", "SEXTA", "SABADO", "DOMINGO", "FERIADOS"].includes(name)) {
      setPerfil(prevState => ({
        ...prevState,
        ponto: {
          ...prevState.ponto,
          CargaHoraria: {
            ...prevState.ponto.CargaHoraria,
            [name]: value
          }
        }
      }));
      return; // Retorna imediatamente para não processar o restante do código
    }
  };

  const handleDelete = () => {
    const del_colaborador = colaborador._id.$oid;
    const del_perfil = perfil._id.$oid;
    const del_user = user._id;
    const id_r = colaborador.id_rede;

    if (!del_colaborador || !del_perfil || !del_user || !id_r) {
      alert('Erro: um ou mais IDs estão faltando');
      return;
    }

    const del = new deleteUser();

    del.deleteData(del_colaborador, del_perfil, del_user, id_r)
      .then(response => {
        if (response.status === '200') {
          handleResetForm();
          setTabIndex(0);
        }
      })
      .catch(error => {
        console.error('Ocorreu um erro ao tentar excluir os dados:', error);
      });
  };

  const handleResetForm = () => {
    setColaborador({
      _id: '',
      cpf_cnpj: '',
      rg: '',
      data_nascimento: '',
      nome: '',
      sobrenome: '',
      nome_social: '',
      Sexo: '',
      Genero: '',
      blocked: false,
      inactive: false,
      endereco: {
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        cidade: '',
        estado: ''
      },
      contato: {
        telefone_fixo: '',
        celular: '',
        email: '',
        WhatsApp: '',
        observacoes: ''
      },
      dadosprofissionais: {
        cargo: '',
        departamento: '',
        data_admissao: '',
        numero_registro: ''
      }
    });

    setPerfil({
      _id: '',
      id_ligacao: '',
      nomeUser: '',
      empresa: '',
      blocked: false,
      inactive: false,
      isVisibleCliente: false,
      TipoCliente: 0,
      isVisibleColaborador: false,
      TipoColaborador: 0,
      isVisibleVisita: false,
      TipoVisita: 0,
      isVisibleEstabelecimento: false,
      TipoEstabelecimento: 0,
      isVisibleCockpit: false,
      TipoCockpit: 0,
      isVisibleProduto: false,
      TipoProduto: 0,
      isVisibleRelatorio: false,
      TipoRelatorio: 0,
      isVisibleProduto_app: false,
      TipoPonto: 0,
      isVisiblePonto: false,
      isVisiblegestaoponto: false,
      ponto: {
        Aprovador: [],
        CargaHoraria: {
          SEGUNDA: '',
          TERCA: '',
          QUARTA: '',
          QUINTA: '',
          SEXTA: '',
          SABADO: '',
          DOMINGO: '',
          FERIADOS: '',
        }
      }
    });

    setUser({
      _id: '',
      id_unico: '',
      username: '',
      password: '',
      usuario_app: '',
      id_r: '',
      id_l: '',
      tipo_app: '',
      blocked: false,
      inactive: false
    });

    setTabIndex(0);
  };

  const generatePassword = () => {
    const length = 12;
    const charset = process.env.REACT_APP_X;
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    setUser({ ...user, password });
  };

  const moveFocus = (e, nextFieldName) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (nextFieldName) {
        document.getElementsByName(nextFieldName)[0].focus();
      } else {
        // Se não houver próximo campo, muda para a próxima aba
        setTabIndex((prevIndex) => prevIndex + 1);
      }
    }
  };

  return (
    <>
      <Header />
      {/* <div style={{
        position: 'fixed',
        top: 80,
        right: 20,
        zIndex: 1000,
        cursor: 'pointer'
      }}>
        <FaQuestionCircle
          size={28}
          color="#cf0505"
          title="Ajuda em vídeo"
          onClick={() => setMostrarAjuda(true)}
        />
      </div> */}
      {/* Exibe o modal com o vídeo ao clicar no ícone */}
      {/* <ExibirWiki
        link='https://www.youtube.com/embed/ajqFTS60cyU'
        isOpen={mostrarAjuda}
        onClose={() => setMostrarAjuda(false)}
      /> */}
      <S.BackButton onClick={() => navigate('/home')}>
        <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
        Voltar
      </S.BackButton>
      <S.Container>
        <S.Form onSubmit={handleSubmit}>
          <S.Title>
            <FormattedMessage id="form.title2" defaultMessage="Cadastro de Colaborador" />
          </S.Title>
          <CabecarioIcon title="Cadastro de Colaborador" />
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab>
                <FormattedMessage id="tabs.colab" defaultMessage="Colaborador" />
              </Tab>
              <Tab>
                <FormattedMessage id="tabs.address" defaultMessage="Endereço" />
              </Tab>
              <Tab>
                <FormattedMessage id="tabs.contact" defaultMessage="Contato" />
              </Tab>
              <Tab>
                <FormattedMessage id="tabs.profi" defaultMessage="Dados Profissionais" />
              </Tab>
              {secureStorage.getItem("TipoColaborador") === 3 && (
                <>
                  <Tab>
                    <FormattedMessage id="tabs.profil_acessos" defaultMessage="Permissões e Acesso" />
                  </Tab>
                </>
              )}
              <Tab>
                <FormattedMessage id="tabs.profil_usuario" defaultMessage="Usuários do Sistema" />
              </Tab>
              {secureStorage.getItem("TipoColaborador") === 3 && (
                <Tab>
                  <FormattedMessage id="tabs.Sistema_Ponto" defaultMessage="Sistema Ponto" />
                </Tab>
              )}
            </TabList>
            <TabPanel>
              {/* Campos básicos */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.name" defaultMessage="Nome:" />
                </S.Label>
                <S.Input
                  name="nome"
                  value={colaborador.nome}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'sobrenome')}
                />
              </S.InputGroup>

              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.sobrenome" defaultMessage="Sobrenome:" />
                </S.Label>
                <S.Input
                  name="sobrenome"
                  value={colaborador.sobrenome}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'nome_social')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.nome_social" defaultMessage="Nome Social:" />
                </S.Label>
                <S.Input
                  name="nome_social"
                  value={colaborador.nome_social}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'Sexo')}
                />
              </S.InputGroup>
              <S.FlexContainer>
                <S.FlexItem>
                  <S.InputGroup>
                    <S.Label>
                      <FormattedMessage id="form.Sexo" defaultMessage="Sexo:" />
                    </S.Label>
                    <S.Select
                      name="Sexo"
                      value={colaborador.Sexo}
                      onChange={e => setColaborador({ ...colaborador, Sexo: e.target.value })}
                      onKeyDown={(e) => moveFocus(e, 'Genero')}
                    >
                      <option value="">Selecionar</option>
                      <option value="Masculino">Masculino</option>
                      <option value="Feminino">Feminino</option>
                    </S.Select>
                  </S.InputGroup>
                </S.FlexItem>
                <S.FlexItem>
                  <S.InputGroup>
                    <S.Label>
                      <FormattedMessage id="form.Genero" defaultMessage="Gênero:" />
                    </S.Label>
                    <S.Select
                      name="Genero"
                      value={colaborador.Genero}
                      onChange={e => setColaborador({ ...colaborador, Genero: e.target.value })}
                      onKeyDown={(e) => moveFocus(e, 'cpf_cnpj')}
                    >
                      <option value="">Selecionar</option>
                      <option value="Masculino">Masculino</option>
                      <option value="Feminino">Feminino</option>
                      <option value="Cis">Cisgênero</option>
                      <option value="Trans">Transgênero</option>
                      <option value="Transfeminino">Transfeminino</option>
                      <option value="Transmasculino">Transmasculino</option>
                      <option value="Não-binário">Não-binário</option>
                      <option value="Gênero Fluido">Gênero Fluido</option>
                      <option value="Agênero">Agênero</option>
                      <option value="Bigênero">Bigênero</option>
                      <option value="Pangênero">Pangênero</option>
                      <option value="Andrógino">Andrógino</option>
                      <option value="Gênero Queer">Gênero Queer</option>
                      <option value="Dois Espíritos">Dois Espíritos</option>
                      <option value="Gênero Variado">Gênero Variado</option>
                      <option value="Genderqueer">Genderqueer</option>
                      <option value="Demigênero">Demigênero</option>
                      <option value="Demiboy">Demiboy</option>
                      <option value="Demigirl">Demigirl</option>
                      <option value="Gênero Não Conformista">Gênero Não Conformista</option>
                      <option value="Gênero Neutro">Gênero Neutro</option>
                      <option value="Gênero Questionante">Gênero Questionante</option>
                      <option value="Intergênero">Intergênero</option>
                      <option value="Neutrois">Neutrois</option>
                      <option value="Multigênero">Multigênero</option>
                      <option value="Poligênero">Poligênero</option>
                      <option value="Trigênero">Trigênero</option>
                      <option value="Gênero Androginia">Gênero Androginia</option>
                      <option value="Intersexo">Intersexo</option>
                      <option value="Gênero Diverso">Gênero Diverso</option>
                      <option value="Transhomem">Transhomem</option>
                      <option value="Transmulher">Transmulher</option>
                      <option value="Transexual">Transexual</option>
                      <option value="Homem Trans">Homem Trans</option>
                      <option value="Mulher Trans">Mulher Trans</option>
                      <option value="Feminino a Masculino">Feminino a Masculino</option>
                      <option value="Masculino a Feminino">Masculino a Feminino</option>
                      <option value="Crossdresser">Crossdresser</option>
                      <option value="Travesti">Travesti</option>
                      <option value="Homem">Homem</option>
                      <option value="Mulher">Mulher</option>
                      <option value="Pessoa Trans">Pessoa Trans</option>
                      <option value="Queer">Queer</option>
                      <option value="Outros">Outros</option>
                      <option value="Prefiro Não Dizer">Prefiro Não Dizer</option>
                      <option value="Gênero Plural">Gênero Plural</option>
                      <option value="Gênero Androginia">Gênero Androginia</option>
                      <option value="Androginia">Androginia</option>
                      <option value="Gênero Múltiplo">Gênero Múltiplo</option>
                      <option value="Gênero Fluído">Gênero Fluído</option>
                    </S.Select>
                  </S.InputGroup>
                </S.FlexItem>
              </S.FlexContainer>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cpf_cnpj" defaultMessage="CPF/CNPJ:" />
                </S.Label>
                <S.Input
                  name="cpf_cnpj"
                  value={colaborador.cpf_cnpj}
                  onChange={handleChange}
                  maxLength={18}
                  onKeyDown={(e) => moveFocus(e, 'rg')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.rg" defaultMessage="RG:" />
                </S.Label>
                <S.Input
                  name="rg"
                  value={colaborador.rg}
                  onChange={handleChange}
                  maxLength={12}
                  onKeyDown={(e) => moveFocus(e, 'data_nascimento')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.data_nascimento" defaultMessage="Data de Nascimento:" />
                </S.Label>
                <S.Input
                  name="data_nascimento"
                  value={colaborador.data_nascimento}
                  onChange={handleChange}
                  maxLength={10}
                  onKeyDown={(e) => moveFocus(e, null)} // Final da aba, muda para a próxima aba
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.CheckboxContainer>
                  <S.Label>Bloquear:</S.Label>
                  <S.Checkbox name="blocked" type="checkbox" checked={colaborador.blocked} onChange={(e) => setColaborador({ ...colaborador, blocked: e.target.checked })} />
                </S.CheckboxContainer>
              </S.InputGroup>
            </TabPanel>
            <TabPanel>
              {/* Endereço */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cep" defaultMessage="Cep:" />
                </S.Label>
                <S.Input
                  name="endereco.cep"
                  value={colaborador.endereco.cep}
                  onChange={handleChange}
                  onBlur={onBlurCep}
                  maxLength={9}
                  onKeyDown={(e) => moveFocus(e, 'endereco.rua')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.rua" defaultMessage="Rua:" />
                </S.Label>
                <S.Input
                  name="endereco.rua"
                  value={colaborador.endereco.rua}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'endereco.numero')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.numero" defaultMessage="Numero:" />
                </S.Label>
                <S.Input
                  name="endereco.numero"
                  value={colaborador.endereco.numero}
                  onChange={handleChange}
                  maxLength={10}
                  onKeyDown={(e) => moveFocus(e, 'endereco.complemento')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.complemento" defaultMessage="Complemento:" />
                </S.Label>
                <S.Input
                  name="endereco.complemento"
                  value={colaborador.endereco.complemento}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'endereco.bairro')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.bairro" defaultMessage="Bairro:" />
                </S.Label>
                <S.Input
                  name="endereco.bairro"
                  value={colaborador.endereco.bairro}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'endereco.cidade')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cidade" defaultMessage="Cidade:" />
                </S.Label>
                <S.Input
                  name="endereco.cidade"
                  value={colaborador.endereco.cidade}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'endereco.estado')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.estado" defaultMessage="Estado:" />
                </S.Label>
                <S.Input
                  name="endereco.estado"
                  value={colaborador.endereco.estado}
                  onChange={handleChange}
                  maxLength={2}
                  onKeyDown={(e) => moveFocus(e, null)} // Final da aba, muda para a próxima aba
                />
              </S.InputGroup>
            </TabPanel>

            <TabPanel>
              {/* Contato */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.telefone_fixo" defaultMessage="Telefone_fixo:" />
                </S.Label>
                <S.Input
                  name="contato.telefone_fixo"
                  value={colaborador.contato.telefone_fixo}
                  onChange={handleChange}
                  maxLength={14}
                  onKeyDown={(e) => moveFocus(e, 'contato.celular')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.celular" defaultMessage="Celular:" />
                </S.Label>
                <S.Input
                  name="contato.celular"
                  value={colaborador.contato.celular}
                  onChange={handleChange}
                  maxLength={15}
                  onKeyDown={(e) => moveFocus(e, 'contato.WhatsApp')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.whatsapp" defaultMessage="WhatsApp:" />
                </S.Label>
                <S.Input
                  name="contato.WhatsApp"
                  value={colaborador.contato.WhatsApp}
                  onChange={handleChange}
                  maxLength={15}
                  onKeyDown={(e) => moveFocus(e, 'contato.email')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.email" defaultMessage="Email:" />
                  <FaInfoCircle title="Email deve ser válido" />
                </S.Label>
                <S.Input
                  name="contato.email"
                  value={colaborador.contato.email}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'contato.observacoes')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.observacoes" defaultMessage="Observações:" />
                </S.Label>
                <S.TextArea
                  name="contato.observacoes"
                  value={colaborador.contato.observacoes}
                  onChange={handleChange}
                  maxLength={250}
                  onKeyDown={(e) => moveFocus(e, null)} // Final da aba, muda para a próxima aba
                />
              </S.InputGroup>
            </TabPanel>
            <TabPanel>
              {/* Dados Profissionais */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.TipoEmpresa" defaultMessage="Empresa:" />
                </S.Label>
                <S.Select
                  name="empresa"
                  value={perfil.empresa} // O valor será o cpf_cnpj da empresa selecionada
                  onChange={e => {
                    const selectedValue = e.target.value;

                    // Encontra o registro correspondente ao valor selecionado
                    const selectedRegistro = registros.find(reg => reg.cpf_cnpj === selectedValue);

                    if (selectedRegistro) {
                      // Armazena o cpf_cnpj no estado perfil.empresa
                      setPerfil({ ...perfil, empresa: selectedRegistro.cpf_cnpj });
                      
                    }
                  }}
                >
                  <option value="">Selecionar</option>
                  {registros.map(registro => (
                    <option key={registro._id.$oid} value={registro.cpf_cnpj}>
                      {registro.nome_fantasia}
                    </option>
                  ))}
                </S.Select>




              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cargo" defaultMessage="Cargo:" />
                </S.Label>
                <S.Input
                  name="dadosprofissionais.cargo"
                  value={colaborador.dadosprofissionais.cargo}
                  onChange={handleChange}
                  maxLength={250}
                  onKeyDown={(e) => moveFocus(e, 'dadosprofissionais.departamento')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.departamento" defaultMessage="Departamento:" />
                </S.Label>
                <S.Input
                  name="dadosprofissionais.departamento"
                  value={colaborador.dadosprofissionais.departamento}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'dadosprofissionais.data_admissao')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.data_admissao" defaultMessage="Data de Admissão:" />
                </S.Label>
                <S.Input
                  name="dadosprofissionais.data_admissao"
                  value={colaborador.dadosprofissionais.data_admissao}
                  onChange={handleChange}
                  maxLength={10}
                  onKeyDown={(e) => moveFocus(e, 'dadosprofissionais.numero_registro')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.numero_registro" defaultMessage="Número de Registro:" />
                </S.Label>
                <S.Input
                  name="dadosprofissionais.numero_registro"
                  value={colaborador.dadosprofissionais.numero_registro}
                  onChange={handleChange}
                  maxLength={100}
                  onKeyDown={(e) => moveFocus(e, null)} // Final da aba, muda para a próxima aba
                />
              </S.InputGroup>
            </TabPanel>


            <TabPanel>
              {/* Permissões e Acesso */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.nomeUser" defaultMessage="Nome do Usuário:" />
                </S.Label>
                <S.Input
                  name="nomeUser"
                  value={perfil.nomeUser}
                  maxLength={255}
                  onChange={e => setPerfil({ ...perfil, nomeUser: e.target.value })}
                  onKeyDown={(e) => moveFocus(e, 'isVisibleCliente')}
                />
              </S.InputGroup>

              {secureStorage.getItem("isVisibleModuloCliente") && (
                <>
                  <S.ModuleSeparator>
                    <S.ModuleTitle onClick={() => toggleModule('cliente')}>
                      <FormattedMessage id="form.moduleCliente" defaultMessage="Módulo Cliente" />
                    </S.ModuleTitle>
                  </S.ModuleSeparator>

                  {visibleModules.cliente && (
                    <>
                      <S.ToggleContainer>
                        <S.ToggleLabel>
                          <FormattedMessage id="form.isVisibleCliente" defaultMessage="Clientes:" />
                        </S.ToggleLabel>
                        <S.ToggleSwitch
                          isActive={perfil.isVisibleCliente}
                          onClick={() => setPerfil({ ...perfil, isVisibleCliente: !perfil.isVisibleCliente })}
                        />
                      </S.ToggleContainer>

                      <S.InputGroup>
                        <S.Label>
                          <FormattedMessage id="form.TipoCliente" defaultMessage="Tipo de Cliente:" />
                        </S.Label>
                        <S.Select
                          name="TipoCliente"
                          value={perfil.TipoCliente}
                          onChange={(e) => setPerfil({ ...perfil, TipoCliente: Number(e.target.value) })}
                          onKeyDown={(e) => moveFocus(e, 'isVisibleColaborador')}
                        >
                          <option value="0">Selecionar</option>
                          <option value="1">Visualizar</option>
                          <option value="2">Visualizar e Alterar e Cadastrar</option>
                          <option value="3">Visualizar Cadastrar e Alterar e Remover</option>
                        </S.Select>
                      </S.InputGroup>
                    </>
                  )}
                </>
              )}
              {secureStorage.getItem("isVisibleModuloColaborador") && (
                <>
                  <S.ModuleSeparator>
                    <S.ModuleTitle onClick={() => toggleModule('colaborador')}>
                      <FormattedMessage id="form.moduleColaborador" defaultMessage="Módulo Colaborador" />
                    </S.ModuleTitle>
                  </S.ModuleSeparator>

                  {visibleModules.colaborador && (
                    <>
                      <S.ToggleContainer>
                        <S.ToggleLabel>
                          <FormattedMessage id="form.isVisibleColaborador" defaultMessage="Colaborador:" />
                        </S.ToggleLabel>
                        <S.ToggleSwitch
                          isActive={perfil.isVisibleColaborador}
                          onClick={() => setPerfil({ ...perfil, isVisibleColaborador: !perfil.isVisibleColaborador })}
                        />
                      </S.ToggleContainer>

                      <S.InputGroup>
                        <S.Label>
                          <FormattedMessage id="form.TipoColaborador" defaultMessage="Tipo de Colaborador:" />
                        </S.Label>
                        <S.Select
                          name="TipoColaborador"
                          value={perfil.TipoColaborador}
                          onChange={(e) => setPerfil({ ...perfil, TipoColaborador: Number(e.target.value) })}
                          onKeyDown={(e) => moveFocus(e, 'isVisibleVisita')}
                        >
                          <option value="0">Selecionar</option>
                          <option value="1">Visualizar</option>
                          <option value="2">Visualizar e Alterar e Cadastrar</option>
                          <option value="3">Visualizar Cadastrar e Alterar e Remover</option>
                        </S.Select>
                      </S.InputGroup>
                    </>
                  )}
                </>
              )}


              {secureStorage.getItem("isVisibleModuloAtendimentos") && (
                <>
                  <S.ModuleSeparator>
                    <S.ModuleTitle onClick={() => toggleModule('atendimentos')}>
                      <FormattedMessage id="form.moduleVisita" defaultMessage="Módulo Visita" />
                    </S.ModuleTitle>
                  </S.ModuleSeparator>

                  {visibleModules.atendimentos && (
                    <>
                      <S.ToggleContainer>
                        <S.ToggleLabel>
                          <FormattedMessage id="form.isVisibleVisita" defaultMessage="Visita:" />
                        </S.ToggleLabel>
                        <S.ToggleSwitch
                          isActive={perfil.isVisibleVisita}
                          onClick={() => setPerfil({ ...perfil, isVisibleVisita: !perfil.isVisibleVisita })}
                        />
                      </S.ToggleContainer>

                      <S.InputGroup>
                        <S.Label>
                          <FormattedMessage id="form.TipoVisita" defaultMessage="Tipo de Atendimento:" />
                        </S.Label>
                        <S.Select
                          name="TipoVisita"
                          value={perfil.TipoVisita}
                          onChange={(e) => setPerfil({ ...perfil, TipoVisita: Number(e.target.value) })}
                          onKeyDown={(e) => moveFocus(e, 'isVisibleEstabelecimento')}
                        >
                          <option value="0">Selecionar</option>
                          <option value="1">Visualizar</option>
                          <option value="2">Visualizar e Alterar e Cadastrar</option>
                          <option value="3">Visualizar Cadastrar e Alterar e Remover</option>
                        </S.Select>
                      </S.InputGroup>
                    </>
                  )}
                </>
              )}

              {secureStorage.getItem("isVisibleModuloEstabelecimento") && (
                <>
                  <S.ModuleSeparator>
                    <S.ModuleTitle onClick={() => toggleModule('estabelecimento')}>
                      <FormattedMessage id="form.moduleEstabelecimento" defaultMessage="Módulo Estabelecimento" />
                    </S.ModuleTitle>
                  </S.ModuleSeparator>

                  {visibleModules.estabelecimento && (
                    <>
                      <S.ToggleContainer>
                        <S.ToggleLabel>
                          <FormattedMessage id="form.isVisibleEstabelecimento" defaultMessage="Estabelecimento:" />
                        </S.ToggleLabel>
                        <S.ToggleSwitch
                          isActive={perfil.isVisibleEstabelecimento}
                          onClick={() => setPerfil({ ...perfil, isVisibleEstabelecimento: !perfil.isVisibleEstabelecimento })}
                        />
                      </S.ToggleContainer>

                      <S.InputGroup>
                        <S.Label>
                          <FormattedMessage id="form.TipoEstabelecimento" defaultMessage="Tipo de Estabelecimento:" />
                        </S.Label>
                        <S.Select
                          name="TipoEstabelecimento"
                          value={perfil.TipoEstabelecimento}
                          onChange={(e) => setPerfil({ ...perfil, TipoEstabelecimento: Number(e.target.value) })}
                          onKeyDown={(e) => moveFocus(e, 'isVisibleCockpit')}
                        >
                          <option value="0">Selecionar</option>
                          <option value="1">Visualizar</option>
                          <option value="2">Visualizar e Alterar e Cadastrar</option>
                          <option value="3">Visualizar Cadastrar e Alterar e Remover</option>
                        </S.Select>
                      </S.InputGroup>
                    </>
                  )}
                </>
              )}


              {secureStorage.getItem("isVisibleModuloQuadroatendimentos") && (
                <>
                  <S.ModuleSeparator>
                    <S.ModuleTitle onClick={() => toggleModule('cockpit')}>
                      <FormattedMessage id="form.moduleCockpit" defaultMessage="Módulo Cockpit" />
                    </S.ModuleTitle>
                  </S.ModuleSeparator>

                  {visibleModules.cockpit && (
                    <>
                      <S.ToggleContainer>
                        <S.ToggleLabel>
                          <FormattedMessage id="form.isVisibleCockpit" defaultMessage="Cockpit:" />
                        </S.ToggleLabel>
                        <S.ToggleSwitch
                          isActive={perfil.isVisibleCockpit}
                          onClick={() => setPerfil({ ...perfil, isVisibleCockpit: !perfil.isVisibleCockpit })}
                        />
                      </S.ToggleContainer>

                      <S.InputGroup>
                        <S.Label>
                          <FormattedMessage id="form.TipoCockpit" defaultMessage="Tipo de Cockpit:" />
                        </S.Label>
                        <S.Select
                          name="TipoCockpit"
                          value={perfil.TipoCockpit}
                          onChange={(e) => setPerfil({ ...perfil, TipoCockpit: Number(e.target.value) })}
                          onKeyDown={(e) => moveFocus(e, 'isVisibleProduto')}
                        >
                          <option value="0">Selecionar</option>
                          <option value="1">Visualizar</option>
                          <option value="2">Visualizar e Alterar e Cadastrar</option>
                          <option value="3">Visualizar Cadastrar e Alterar e Remover</option>
                        </S.Select>
                      </S.InputGroup>
                    </>
                  )}
                </>
              )}



              {secureStorage.getItem("isVisibleModuloProduto") && (
                <>
                  <S.ModuleSeparator>
                    <S.ModuleTitle onClick={() => toggleModule('produto')}>
                      <FormattedMessage id="form.moduleProduto" defaultMessage="Módulo Produto" />
                    </S.ModuleTitle>
                  </S.ModuleSeparator>

                  {visibleModules.produto && (
                    <>
                      <S.ToggleContainer>
                        <S.ToggleLabel>
                          <FormattedMessage id="form.isVisibleProdutoapp" defaultMessage="Produto App:" />
                        </S.ToggleLabel>
                        <S.ToggleSwitch
                          isActive={perfil.isVisibleProduto_app}
                          onClick={() => setPerfil({ ...perfil, isVisibleProduto_app: !perfil.isVisibleProduto_app })}
                        />
                      </S.ToggleContainer>

                      <S.ToggleContainer>
                        <S.ToggleLabel>
                          <FormattedMessage id="form.isVisibleProduto" defaultMessage="Produto:" />
                        </S.ToggleLabel>
                        <S.ToggleSwitch
                          isActive={perfil.isVisibleProduto}
                          onClick={() => setPerfil({ ...perfil, isVisibleProduto: !perfil.isVisibleProduto })}
                        />
                      </S.ToggleContainer>

                      <S.InputGroup>
                        <S.Label>
                          <FormattedMessage id="form.TipoProduto" defaultMessage="Tipo de Produto:" />
                        </S.Label>
                        <S.Select
                          name="TipoProduto"
                          value={perfil.TipoProduto}
                          onChange={(e) => setPerfil({ ...perfil, TipoProduto: Number(e.target.value) })}
                          onKeyDown={(e) => moveFocus(e, null)} // Final da aba, muda para a próxima aba
                        >
                          <option value="0">Selecionar</option>
                          <option value="1">Visualizar</option>
                          <option value="2">Visualizar e Alterar e Cadastrar</option>
                          <option value="3">Visualizar Cadastrar e Alterar e Remover</option>
                        </S.Select>
                      </S.InputGroup>
                    </>
                  )}
                </>
              )}


              {secureStorage.getItem("isVisibleModuloPonto") && (
                <>
                  <S.ModuleSeparator>
                    <S.ModuleTitle onClick={() => toggleModule('ponto')}>
                      <FormattedMessage id="form.modulePonto" defaultMessage="Módulo Ponto" />
                    </S.ModuleTitle>
                  </S.ModuleSeparator>

                  {visibleModules.ponto && (
                    <>
                      <S.ToggleContainer>
                        <S.ToggleLabel>
                          <FormattedMessage id="form.isVisiblePonto" defaultMessage="Ponto:" />
                        </S.ToggleLabel>
                        <S.ToggleSwitch
                          isActive={perfil.isVisiblePonto}
                          onClick={() => setPerfil({ ...perfil, isVisiblePonto: !perfil.isVisiblePonto })}
                        />
                      </S.ToggleContainer>

                      <S.ToggleContainer>
                        <S.ToggleLabel>
                          <FormattedMessage id="form.isVisiblegestaoponto" defaultMessage="GestãoPonto:" />
                        </S.ToggleLabel>
                        <S.ToggleSwitch
                          isActive={perfil.isVisiblegestaoponto}
                          onClick={() => setPerfil({ ...perfil, isVisiblegestaoponto: !perfil.isVisiblegestaoponto })}
                        />
                      </S.ToggleContainer>

                      <S.InputGroup>
                        <S.Label>
                          <FormattedMessage id="form.TipoPonto" defaultMessage="Tipo de Ponto:" />
                        </S.Label>
                        <S.Select
                          name="TipoPonto"
                          value={perfil.TipoPonto}
                          onChange={(e) => setPerfil({ ...perfil, TipoPonto: Number(e.target.value) })}
                          onKeyDown={(e) => moveFocus(e, 'isVisibleRelatorio')} // Final da aba, muda para a próxima aba
                        >
                          <option value="0">Selecionar</option>
                          <option value="1">Visualizar</option>
                          <option value="2">Visualizar e Alterar e Cadastrar</option>
                          <option value="3">Visualizar Cadastrar e Alterar e Remover</option>
                        </S.Select>
                      </S.InputGroup>
                    </>
                  )}
                </>
              )}

              {secureStorage.getItem("isVisibleModuloRelatorio") && (
                <>
                  <S.ModuleSeparator>
                    <S.ModuleTitle onClick={() => toggleModule('relatorio')}>
                      <FormattedMessage id="form.moduleRelatorio" defaultMessage="Módulo Relatório" />
                    </S.ModuleTitle>
                  </S.ModuleSeparator>

                  {visibleModules.relatorio && (
                    <>
                      <S.ToggleContainer>
                        <S.ToggleLabel>
                          <FormattedMessage id="form.isVisibleRelatorio" defaultMessage="Relatório:" />
                        </S.ToggleLabel>
                        <S.ToggleSwitch
                          isActive={perfil.isVisibleRelatorio}
                          onClick={() => setPerfil({ ...perfil, isVisibleRelatorio: !perfil.isVisibleRelatorio })}
                        />
                      </S.ToggleContainer>

                      <S.InputGroup>
                        <S.Label>
                          <FormattedMessage id="form.TipoRelatorio" defaultMessage="Tipo de Relatório:" />
                        </S.Label>
                        <S.Select
                          name="TipoRelatorio"
                          value={perfil.TipoRelatorio}
                          onChange={(e) => setPerfil({ ...perfil, TipoRelatorio: Number(e.target.value) })}
                          onKeyDown={(e) => moveFocus(e, null)} // Final da aba, muda para a próxima aba
                        >
                          <option value="0">Selecionar</option>
                          <option value="1">Visualizar</option>
                          <option value="2">Visualizar e Alterar e Cadastrar</option>
                          <option value="3">Visualizar Cadastrar e Alterar e Remover</option>
                        </S.Select>
                      </S.InputGroup>
                    </>
                  )}
                </>
              )}

            </TabPanel>


            <TabPanel>
              {/* Usuários do Sistema */}
              <S.InputGroup>
                <S.Label>Username: <FaInfoCircle title="Username deve ser um email válido" /></S.Label>
                <S.Input
                  name="user.username"
                  type="text"
                  value={user.username}
                  autoComplete="off"
                  maxLength={255}
                  onChange={e => {
                    setUser({ ...user, username: e.target.value.trim() });
                  }}
                  onKeyDown={(e) => moveFocus(e, 'password')}
                />
              </S.InputGroup>

              <S.InputGroup>
                <S.Label>Password: <FaInfoCircle title="A senha deve ter de 8 a 32 caracteres, incluindo letras, números e caracteres especiais" /></S.Label>
                <S.Input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={user.password}
                  autoComplete="off"
                  maxLength={32}
                  onChange={e => {
                    setUser({ ...user, password: e.target.value });
                    const password = e.target.value;
                    if (password.length < 8 || password.length > 32 || !/[A-Z]/.test(password) || !/[0-9]/.test(password) || !/[!@#$%&]/.test(password)) {
                      setErro('A senha deve ter de 8 a 32 caracteres, incluindo letras maiúsculas, números e caracteres especiais');
                    } else {
                      setErro(null);
                    }
                  }}
                  onKeyDown={(e) => moveFocus(e, 'tipo_app')}
                />
                <S.ButtonWrapper>
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ padding: '0 10px', cursor: 'pointer' }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                  <S.SmallButton type="button" onClick={generatePassword}>Gerar Senha</S.SmallButton>
                </S.ButtonWrapper>
              </S.InputGroup>


              <S.InputGroup>
                <S.Label>Tipo App: *</S.Label>
                <S.Select name="tipo_app" value={user.tipo_app} onChange={e => setUser({ ...user, tipo_app: Number(e.target.value) })} onKeyDown={(e) => moveFocus(e, null)} >
                  <option value="0">Selecionar</option>
                  <option value="1">Web</option>
                  <option value="2">App</option>
                  <option value="3">Web_App</option>
                </S.Select>
              </S.InputGroup>
            </TabPanel>
            {secureStorage.getItem("TipoColaborador") === 3 && (

              <TabPanel>
                {/* Carga Horária */}
                <S.ModuleSeparator>
                  <S.ModuleTitle>
                    <FormattedMessage id="form.GestordePonto" defaultMessage="Gestor de Ponto" />
                  </S.ModuleTitle>
                </S.ModuleSeparator>
                <S.InputGroup2>

                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        backgroundColor: '#fff',
                      }}
                      onClick={() => setDropdownOpen(!dropdownOpen)} // Abre ou fecha o dropdown
                    >
                      {perfil.ponto.Aprovador && perfil.ponto.Aprovador.length > 0
                        ? perfil.ponto.Aprovador.map((id) => {
                          const opcao = opcoes.find((op) => op.id_ligacao === id);
                          return opcao ? opcao.nomeUser : null;
                        }).join(', ')
                        : 'Selecionar'}
                    </div>

                    {dropdownOpen && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '100%',
                          left: 0,
                          width: '100%',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          backgroundColor: '#fff',
                          zIndex: 10,
                          maxHeight: '150px',
                          overflowY: 'auto',
                        }}
                      >
                        {opcoes.map((opcao) => (
                          <div key={opcao.id_ligacao} style={{ padding: '8px', display: 'flex', alignItems: 'center' }}>
                            <input
                              type="checkbox"
                              checked={perfil.ponto.Aprovador?.includes(opcao.id_ligacao)}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                const aprovadores = perfil.ponto.Aprovador || [];

                                const updatedAprovadores = isChecked
                                  ? [...aprovadores, opcao.id_ligacao]
                                  : aprovadores.filter((id) => id !== opcao.id_ligacao);

                                setPerfil({
                                  ...perfil,
                                  ponto: {
                                    ...perfil.ponto,
                                    Aprovador: updatedAprovadores,
                                  },
                                });
                              }}
                            />
                            <label style={{ marginLeft: '8px' }}>{opcao.nomeUser}</label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>


                </S.InputGroup2>

                <S.ModuleSeparator>
                  <S.ModuleTitle>
                    <FormattedMessage id="form.moduleCargaHorária" defaultMessage="Carga Horária" />
                  </S.ModuleTitle>
                </S.ModuleSeparator>
                <S.FlexContainer>
                  <S.FlexItem>
                    <S.InputGroup>
                      <S.Label>
                        <FormattedMessage id="form.SEGUNDA" defaultMessage="SEGUNDA" />
                      </S.Label>
                      <S.Input type="time"
                        name="SEGUNDA"
                        value={perfil.ponto.CargaHoraria.SEGUNDA || ''}
                        onChange={handleChange}
                        onKeyDown={(e) => moveFocus(e, 'TERCA')}
                      />
                    </S.InputGroup>
                  </S.FlexItem>
                  <S.FlexItem>
                    <S.InputGroup>
                      <S.Label>
                        <FormattedMessage id="form.TERCA" defaultMessage="TERÇA" />
                      </S.Label>
                      <S.Input type="time"
                        name="TERCA"
                        value={perfil.ponto.CargaHoraria.TERCA || ''}
                        onChange={handleChange}
                        onKeyDown={(e) => moveFocus(e, 'QUARTA')}
                      />
                    </S.InputGroup>
                  </S.FlexItem>
                  <S.FlexItem>
                    <S.InputGroup>
                      <S.Label>
                        <FormattedMessage id="form.QUARTA" defaultMessage="QUARTA" />
                      </S.Label>
                      <S.Input type="time"
                        name="QUARTA"
                        value={perfil.ponto.CargaHoraria.QUARTA || ''}
                        onChange={handleChange}
                        onKeyDown={(e) => moveFocus(e, 'QUINTA')}
                      />
                    </S.InputGroup>
                  </S.FlexItem>
                  <S.FlexItem>
                    <S.InputGroup>
                      <S.Label>
                        <FormattedMessage id="form.QUINTA" defaultMessage="QUINTA" />
                      </S.Label>
                      <S.Input type="time"
                        name="QUINTA"
                        value={perfil.ponto.CargaHoraria.QUINTA || ''}
                        onChange={handleChange}
                        onKeyDown={(e) => moveFocus(e, 'SEXTA')}
                      />
                    </S.InputGroup>
                  </S.FlexItem>
                  <S.FlexItem>
                    <S.InputGroup>
                      <S.Label>
                        <FormattedMessage id="form.SEXTA" defaultMessage="SEXTA" />
                      </S.Label>
                      <S.Input type="time"
                        name="SEXTA"
                        value={perfil.ponto.CargaHoraria.SEXTA || ''}
                        onChange={handleChange}
                        onKeyDown={(e) => moveFocus(e, 'SABADO')}
                      />
                    </S.InputGroup>
                  </S.FlexItem>
                  <S.FlexItem>
                    <S.InputGroup>
                      <S.Label>
                        <FormattedMessage id="form.SABADO" defaultMessage="SÁBADO" />
                      </S.Label>
                      <S.Input type="time"
                        name="SABADO"
                        value={perfil.ponto.CargaHoraria.SABADO || ''}
                        onChange={handleChange}
                        onKeyDown={(e) => moveFocus(e, null)}
                      />
                    </S.InputGroup>
                  </S.FlexItem>
                  <S.FlexItem>
                    <S.InputGroup>
                      <S.Label>
                        <FormattedMessage id="form.DOMINGO" defaultMessage="DOMINGO" />
                      </S.Label>
                      <S.Input type="time"
                        name="DOMINGO"
                        value={perfil.ponto.CargaHoraria.DOMINGO || ''}
                        onChange={handleChange}
                        onKeyDown={(e) => moveFocus(e, 'FERIADOS')}
                      />
                    </S.InputGroup>
                  </S.FlexItem>
                  <S.FlexItem>
                    <S.InputGroup>
                      <S.Label>
                        <FormattedMessage id="form.FERIADOS" defaultMessage="FERIADOS" />
                      </S.Label>
                      <S.Input type="time"
                        name="FERIADOS"
                        value={perfil.ponto.CargaHoraria.FERIADOS || ''}
                        onChange={handleChange}
                        onKeyDown={(e) => moveFocus(e, null)}
                      />
                    </S.InputGroup>
                  </S.FlexItem>
                </S.FlexContainer>
              </TabPanel>
            )}
          </Tabs>
          {erro && <S.Erro>{erro}</S.Erro>}
          <S.ButtonGroup>
            <S.Button type="button" onClick={handleOpenModal}>
              <FormattedMessage id="form.buscar_cadastros" defaultMessage="Buscar Cadastros" />
            </S.Button>
            {secureStorage.getItem("TipoColaborador") !== 1 && (
              <S.Button type="submit">
                <FormattedMessage
                  id={colaborador._id ? "form.salvar" : "form.cadastrar"}
                  defaultMessage={colaborador._id ? "Salvar" : "Cadastrar"}
                />
              </S.Button>
            )}
            {colaborador._id && secureStorage.getItem("TipoColaborador") === 3 && (
              <S.Button type="button" onClick={handleDelete}>
                <FormattedMessage id="form.excluir" defaultMessage="Excluir Cadastro" />
              </S.Button>
            )}
            {secureStorage.getItem("TipoColaborador") !== 1 && (
              <S.Button type="button" onClick={handleResetForm}>
                <FormattedMessage id="form.novo" defaultMessage="Novo" />
              </S.Button>
            )}
          </S.ButtonGroup>
        </S.Form>
        <S.StyledModal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel={contentLabel}
          ariaHideApp={false}
        >
          <S.Input
            type="text"
            placeholder="Digite o nome para buscar..."
            value={nome}
            onChange={(e) => handleSearchChange(e, setNome)}
            style={{ marginBottom: '10px' }}
          />
          <S.ModalContent>
            {Array.isArray(searchResults) && searchResults.length > 0 ? (
              <S.StyledTable>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>sobrenome</th>
                    <th>Contato</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults
                    .filter(colaborador =>
                      colaborador.nome.toLowerCase().includes(nome.toLowerCase())
                    )
                    .map((colaborador) => (
                      <tr key={colaborador.cpf_cnpj} onClick={() => handleSelectcolaborador(colaborador)}>
                        <td>{colaborador.nome}</td>
                        <td>{colaborador.sobrenome}</td>
                        <td>{` Celular: ${colaborador.contato.celular}, Email: ${colaborador.contato.email}, WhatsApp: ${colaborador.contato.WhatsApp}`}</td>
                      </tr>
                    ))}
                </tbody>
              </S.StyledTable>
            ) : (
              <p>Nenhum resultado encontrado.</p>
            )}
          </S.ModalContent>
          <button onClick={handleCloseModal}>Fechar</button>
        </S.StyledModal>
      </S.Container >
      <Footer />
    </>
  );
}

export default Colaborador;
