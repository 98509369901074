import React, { useEffect, useState } from 'react';
import ModalWiki from './ModalWiki';

const ExibirWiki = ({ link, isOpen, onClose }) => {
  const [wikiUrl, setWikiUrl] = useState(null);

  useEffect(() => {
    if (link) {
      setWikiUrl(link);
    }
  }, [link]);

  return (
    <ModalWiki
      isOpen={isOpen && !!wikiUrl}
      onClose={onClose}
      wikiPageUrl={wikiUrl}
    />
  );
};

export default ExibirWiki;
