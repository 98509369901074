import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #f2f2f2;
  font-family: Arial, sans-serif;
  padding-top: 40px; /* Aumente o espaçamento do topo */
  padding-bottom: 50px;
`;


export const MainCard = styled.div`
  width: 90%;
  max-width: 500px;
  background-color: #d9d9d9;
  border: 1px solid red;
  border-radius: 8px;
  text-align: center;
  padding: 16px;
  margin: 40px 0; /* Adiciona margem superior */
  margin-bottom: 16px;

  h2 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    margin: 8px 0;
    font-size: 16px;
  }
`;


export const Card = styled.div`
  width: 100%; /* Faz o card ocupar toda a largura disponível */
  max-width: 1000px; /* Aumenta o comprimento máximo do card */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer; /* Torna o card clicável */

  .card-header {
    display: flex; /* Ativa o layout flex */
    justify-content: space-between; /* Um elemento na esquerda e outro na direita */
    align-items: center; /* Centraliza verticalmente */
    font-size: 14px;

    h4 {
      font-size: 16px;
      margin: 0; /* Remove margens padrão */
      font-weight: bold;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }

  &:hover {
    background-color: #f9f9f9; /* Efeito ao passar o mouse */
  }
`;



export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  div {
    text-align: center;

    p {
      margin: 4px 0;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: blue;
        text-decoration: underline;
      }
    }
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin: 12px 0;
  text-align: center;
`;

// Botão para registrar ponto
export const Button = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  margin: 20px 0;

  &:hover {
    background-color: #218838;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;


export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;

  h3 {
    margin-bottom: 12px;
  }

  button {
    margin-top: 16px;
    padding: 8px 12px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #c82333;
    }
  }
    
`;

export const ButtonFechar = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 32px;
  height: 32px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c82333;
  }
`;

export const InfoCardsContainer = styled.div`
  display: flex;
  justify-content: center; /* Alinha os cards no centro */
  gap: 16px; /* Espaço entre os cards */
  margin-top: 20px;
  margin-bottom: 40px; /* Adiciona mais espaço abaixo */
  flex-wrap: wrap; /* Quebra os cards para a linha de baixo, se necessário */
`;


export const InfoCard = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px 16px;
  text-align: center;
  width: 250px; /* Largura fixa dos cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h3 {
    margin: 0 0 8px 0;
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }
`;
export const BackButton = styled.button`
    position: absolute;
    top: 100px;
    left: 20px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #cf0505;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #800000;
    }
`;            



/* Novos estilos para as abas */
export const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  border-bottom: 1px solid #ccc;
`;

export const TabButton = styled.button`
  background: transparent;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  color: ${({ active }) => (active ? "#000" : "#888")};
  border-bottom: ${({ active }) => (active ? "2px solid #000" : "none")};
  cursor: pointer;
`;





export const CloseIcon = styled.div`
  align-self: flex-end;
  cursor: pointer;
`;

export const ModalTitulo = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
`;



export const ModalTexto = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
`;

export const Label = styled.label`
  font-weight: bold;
`;

export const BotaoCancelar = styled.button`
  margin-top: 20px;
  background-color: #ff4d4d;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
  align-self: center;
  width: 100%;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #cc0000;
  }
`;

export const TextoBotaoCancelar = styled.span`
  color: white;
  font-size: 16px;
  font-weight: bold;
`;