import React, { useState } from 'react';
import styles from './styles'; // Importa o styles.js que você usa no web

import SalvarSolicitacao from '../../services/ponto/SalvarSolicitacao';


import secureStorage from '../../utils/SecureStorage';
import { FaTimes, FaPlus } from 'react-icons/fa';

const SolicitacaoPonto = ({ registro, tipoSolicitacao, isVisible, onClose }) => {
  const [horas, setHoras] = useState(['']);
  const [descricao, setDescricao] = useState('');
  const [motivoSelecionado, setMotivoSelecionado] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [loading, setLoading] = useState(false);

  // Função simples para formatar a data no formato "DD/MM/AAAA"
  const formatarData = (dataISO) => {
    // Verifica se dataISO existe e é uma string no formato esperado
    if (!dataISO || typeof dataISO !== 'string' || !dataISO.includes('-')) {
      return 'Data inválida';  // Retorna uma mensagem de erro ou um valor padrão
    }
  
    const [ano, mes, dia] = dataISO.split('-');
    return `${dia}/${mes}/${ano}`;
  };
  
  const adicionarHora = () => {
    setHoras([...horas, '']);
  };

  const atualizarHora = (text, index) => {
    let valor = text.replace(/\D/g, '');
    if (valor.length > 4) valor = valor.slice(0, 4);

    let horaFormatada = valor;
    if (valor.length >= 3) {
      horaFormatada = `${valor.slice(0, 2)}:${valor.slice(2, 4)}`;
    }

    const novasHoras = [...horas];
    novasHoras[index] = horaFormatada;
    setHoras(novasHoras);
  };

  // Funções de upload estão comentadas; implemente conforme sua necessidade
  /*
  const handleImageUpload = async () => {
    // Lógica para upload de imagem
  };

  const handlePdfUpload = async () => {
    // Lógica para upload de PDF
  };
  */

  // Função para enviar a solicitação para a API
  const enviarSolicitacao = async () => {
    setLoading(true);
    try {
      const idRede = await secureStorage.getItem('id_r');
      const idColaborador = await secureStorage.getItem('id_unico');
      const pontoJSON = await secureStorage.getItem('ponto');
      const ponto = pontoJSON ? JSON.parse(pontoJSON) : {};
      const idAprovador = ponto.Aprovador ? ponto.Aprovador : [];

      const solicitacao = {
        tipo_solicitacao: "ponto",
        id_rede: idRede,
        id_colaborador: idColaborador,
        id_aprovador: idAprovador,
        status: 0,
        atestado: tipoSolicitacao === 'atestado',
        url_pdf_atestado: selectedPdf || null,
        url_imagem_atestado: selectedImage || null,
        motivo: motivoSelecionado,
        descricao: descricao,
        data_criacao: new Date().toISOString(),
        registros_ajuste: horas.filter(hora => hora !== ""),
        registro: registro
      };

      const response = await SalvarSolicitacao(idRede, solicitacao);
    if (response.status === 200 || response.status === 201) {
      window.alert('Sucesso! Solicitação enviada com sucesso!');
      onClose();
    } else {
      console.error('Falha ao enviar solicitação:', response);
      window.alert('Erro: Falha ao enviar solicitação. Tente novamente.');
    }
    } catch (error) {
      console.error('Erro ao enviar solicitação:', error);
      window.alert('Erro: Falha ao enviar solicitação. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  // Se o modal não estiver visível, não renderiza nada
  if (!isVisible) return null;

  return (
    <div style={styles.modalBackground}>
      <div style={styles.modalContainer}>
        <button style={styles.closeIcon} onClick={onClose}>
          <FaTimes size={24} color="black" />
        </button>

        <h2 style={styles.title}>
          {tipoSolicitacao === 'ajuste' ? 'Solicitar Ajuste' : 'Enviar Atestado'}
        </h2>

        <p style={styles.subtitle}>
          Data: {formatarData(registro.data)}
        </p>

        {tipoSolicitacao === 'ajuste' && (
          <div style={styles.pickerContainer}>
            <select
              style={styles.picker}
              value={motivoSelecionado}
              onChange={(e) => setMotivoSelecionado(e.target.value)}
            >
              <option value="">Selecione um motivo</option>
              <option value="Erro no registro de ponto">Erro no registro de ponto</option>
              <option value="Esqueci de bater o ponto ">Esqueci de bater o ponto </option>
              <option value="Saída antes do horário">Saída antes do horário</option>
              <option value="Atividade externa sem registro">Atividade externa sem registro</option>
              <option value="Outro motivo">Outro motivo</option>
            </select>
          </div>
        )}

        <textarea
          style={styles.descricaoInput}
          placeholder="Descreva o motivo do ajuste..."
          rows="3"
          value={descricao}
          onChange={(e) => setDescricao(e.target.value)}
        />

        <div style={styles.horaContainer}>
          {horas.map((hora, index) => (
            <div key={index} style={styles.horaInputContainer}>
              <input
                style={styles.horaInput}
                type="text"
                placeholder="HH:MM"
                value={hora}
                maxLength="5"
                onChange={(e) => atualizarHora(e.target.value, index)}
              />
              <button style={styles.addHoraButton} onClick={adicionarHora}>
                <FaPlus size={20} color="white" />
              </button>
            </div>
          ))}
        </div>

        {tipoSolicitacao === 'atestado' && (
          <div style={styles.uploadContainer}>
            <button style={styles.uploadButton} onClick={() => { /* handleImageUpload() */ }}>
              <span style={styles.uploadButtonText}>Upload de Imagem</span>
            </button>
            <button style={styles.uploadButton} onClick={() => { /* handlePdfUpload() */ }}>
              <span style={styles.uploadButtonText}>Upload de PDF</span>
            </button>
          </div>
        )}

        <button style={styles.salvarButton} onClick={enviarSolicitacao} disabled={loading}>
          <span style={styles.salvarButtonText}>{loading ? 'Enviando...' : 'Salvar'}</span>
        </button>
      </div>
    </div>
  );
};

export default SolicitacaoPonto;
