import { useState } from 'react';
import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const BuscaPontoPordia = () => {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_BASE_62;

  const loadregistros = async (id_rede, data) => {
    if (!id_rede || !data) {
      setError("ID da Rede ou Data não fornecidos.");
      console.error("ID da Rede ou Data não fornecidos.");
      return;
    }

    setLoading(true);
    setError(null);

    console.log(`Iniciando busca de registros com id_rede: ${id_rede} e data: ${data}`);
    try {
      const response = await api.get(`${apiUrl}`, {
        params: {
          id_rede: id_rede,
          data: data, // Passa a data como parâmetro
        },
      });

      console.log("Resposta recebida da API:", response.data);

      if (response.data) {
        // Atualiza o estado com os novos dados
        secureStorage.setItem("dataponto", JSON.stringify(response.data));
        setSearchResults(response.data);
      } else {
        console.warn("Nenhum registro encontrado.");
        setError("Nenhum registro encontrado.");
        setSearchResults([]);
      }
    } catch (error) {
      console.error("Erro ao buscar registros:", error);
      setError("Erro ao buscar registros. Veja o console para mais detalhes.");
      setSearchResults([]);
    } finally {
      setLoading(false);
      console.log("Busca de registros concluída.");
    }
  };

  return { loading, searchResults, error, loadregistros };
};

export default BuscaPontoPordia;
