import React from 'react';
import './PgFaleConosco.css';

// Importando imagens
import chatIcon from '../../assets/chat-icon.png';
import whatsappIcon from '../../assets/whatsapp-icon.png';
import instagramIcon from '../../assets/instagram-icon.png';
import emailIcon from '../../assets/email-icon.png';
import qrCode from '../../assets/marketdobr_qr.png';

function PgFaleConosco() {
  return (
    <>
      {/* Título */}
      <header className="pg-faleconosco-header">
        <h1>Entre em contato conosco! Estamos sempre prontos para atender você.</h1>
        <p>Escolha o canal que você preferir e bora conversar!</p>
      </header>

      {/* Cards */}
      <main>
        <section className="contact-section">
          {/* Card 1 */}
          <div className="contact-item">
            <img src={chatIcon} alt="Ícone Chat" className="contact-icon" />
            <div className="contact-title">Comercial:</div>
            <div className="contact-number">
              <img src={whatsappIcon} alt="WhatsApp Icon" />
              <a href="https://wa.me/5545999315247" target="_blank" rel="noopener noreferrer" className="contact-link">
                (45) 9 9931-5247
              </a>
            </div>
            <div className="contact-number">
              <img src={whatsappIcon} alt="WhatsApp Icon" />
              <a href="https://wa.me/5545999315313" target="_blank" rel="noopener noreferrer" className="contact-link">
                (45) 9 9931-5313
              </a>
            </div>
            <div className="contact-title">Suporte:</div>
            <div className="contact-number">
              <img src={whatsappIcon} alt="WhatsApp Icon" />
              <a href="https://wa.me/5545998475215" target="_blank" rel="noopener noreferrer" className="contact-link">
                (45) 9 9847-5215
              </a>
            </div>
          </div>

          {/* Card 2 */}
          <div className="contact-item">
            <img src={qrCode} alt="QR Code Instagram" className="qr-code" />
            <div className="contact-title">Siga a gente no Instagram:</div>
            <div className="contact-number">
              <img src={instagramIcon} alt="Instagram Icon" />
              <a href="https://www.instagram.com/marketdobr/" target="_blank" rel="noopener noreferrer" className="contact-link">
                @marketdobr
              </a>
            </div>
          </div>

          {/* Card 3 */}
          <div className="contact-item">
            <img src={emailIcon} alt="Ícone Email" className="contact-icon" />
            <div className="contact-title">Prefere nos enviar um e-mail?</div>
            <div className="contact-number">
              <a href="mailto:marketdocomercial@gmail.com" className="contact-link">
              marketdocomercial@gmail.com
              </a>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default PgFaleConosco;
