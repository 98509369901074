import React, { useState, useEffect } from "react";
import * as S from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import useBuscarBatidas from "../../services/ponto/BuscarBatidas";
import enviarBatidas from "../../services/ponto/enviarBatidas";
import BuscarSolicitacaolista from "../../services/ponto/BuscarsolicitacoesUser";
import Updatesolicitacoes from "../../services/ponto/Updatesolicitacoes";
import secureStorage from "../../utils/SecureStorage";
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import SolicitacaoPonto from "../../components/SolicitacaoPonto/SolicitacaoPonto";
import { MdClose } from 'react-icons/md';
import ExibirWiki from '../../components/ExibirWiki/ExibirWiki';
import { FaQuestionCircle } from 'react-icons/fa';

function Ponto() {
    const [batidasHoje, setBatidasHoje] = useState([]);
    const [registros, setRegistros] = useState([]);
    const [registrosselecionado, setRegistrosselecionado] = useState([]);
    const [registroSelecionado, setRegistroSelecionado] = useState(null);
    const [solicitacoes, setSolicitacoes] = useState([]); // estado para solicitações
    const [horasTrabalhadas, setHorasTrabalhadas] = useState("00:00");
    const [saldoBancoHoras, setSaldoBancoHoras] = useState("00:00");
    const [totalAtrasos, setTotalAtrasos] = useState("00:00");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalRegistro, setModalRegistro] = useState(null);
    const [selectedTab, setSelectedTab] = useState("registros");
    const [horasHoje, setHorasHoje] = useState("00:00");
    const navigate = useNavigate();
    const [tipoSolicitacao, setTipoSolicitacao] = useState(null);
    const [mostrarAjuda, setMostrarAjuda] = useState(false);
    const { BuscarBatidas } = useBuscarBatidas();


    const carregarBatidas = async () => {
        setError(null);
        setLoading(true);

        try {
            const id_rede = await secureStorage.getItem("id_r");
            const id_colaborador = await secureStorage.getItem("id_unico");

            const response = await BuscarBatidas(id_rede, id_colaborador);

            if (response && Array.isArray(response.registros)) {
                const registrosOrdenados = response.registros.sort(
                    (a, b) => new Date(b.data) - new Date(a.data)
                );

                setRegistros(registrosOrdenados);
                setHorasTrabalhadas(response.total_horas_trabalhadas || "00:00");
                setSaldoBancoHoras(response.saldo_banco_horas || "00:00");
                setTotalAtrasos(response.total_atrasos || "00:00");

                const agora = new Date();
                agora.setHours(agora.getHours() - 3); // Ajuste para UTC-3
                const hoje = agora.toISOString().split("T")[0];

                const batidasDeHoje = registrosOrdenados.filter(
                    (registro) => registro.data === hoje
                );

                setBatidasHoje(batidasDeHoje);

                if (batidasDeHoje.length > 0) {
                    const horasCalculadas = calcularHorasTrabalhadas(batidasDeHoje[0].batidas);
                    setHorasHoje(horasCalculadas);
                } else {
                    setHorasHoje("00:00");
                }
            } else {
                throw new Error("Estrutura de dados inválida: registros não encontrados.");
            }
        } catch (err) {
            console.error("Erro ao carregar registros:", err.message);
            setError("Erro ao carregar registros. Verifique sua conexão.");
        } finally {
            setLoading(false);
        }
    };

    const fecharDetalhes = () => {
        setRegistroSelecionado(null)
        setTipoSolicitacao(null);
    };

    const carregarSolicitacoes = async () => {
        setLoading(true);
        try {
            const id_rede = await secureStorage.getItem("id_r");
            const id_colaborador = await secureStorage.getItem("id_unico");
            const response = await BuscarSolicitacaolista(id_rede, id_colaborador);
            setSolicitacoes(response.data || []);
        } catch (err) {
            console.error("Erro ao carregar solicitações:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        carregarBatidas();
        carregarSolicitacoes();
    }, []);


    const calcularHorasTrabalhadas = (batidas) => {
        if (!batidas || batidas.length < 2) return "00:00";

        let totalMinutos = 0;

        for (let i = 0; i < batidas.length; i += 2) {
            const entrada = new Date(batidas[i]?.timestamp);
            const saida = batidas[i + 1] ? new Date(batidas[i + 1]?.timestamp) : null;

            if (entrada && saida) {
                const diferencaMinutos = Math.floor((saida - entrada) / (1000 * 60));
                totalMinutos += diferencaMinutos;
            }
        }

        const horas = Math.floor(totalMinutos / 60).toString().padStart(2, "0");
        const minutos = (totalMinutos % 60).toString().padStart(2, "0");

        return `${horas}:${minutos}`;
    };

    const handleRegistrarPonto = async () => {
        setLoading(true);

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;

                    try {
                        const id_rede = await secureStorage.getItem("id_r");
                        const empresa = await secureStorage.getItem("empresaponto");
                        const colaborador = await secureStorage.getItem("nomeUser");
                        const id_colaborador = await secureStorage.getItem("id_unico");
                        const ponto = JSON.parse(await secureStorage.getItem("ponto"));
                        const dataAtual = new Date().toISOString().split("T")[0];
                        const Processado = false;
                        const Solicitacao = false;
                        const Feriado = false;
                        const Ferias = false;
                        const Atraso = '00:00';
                        const Total_de_Horas = '00:00';
                        const Hora_Extra = '00:00';

                        if (!id_rede || !id_colaborador || !empresa || !ponto) {
                            throw new Error("Informações incompletas para enviar as batidas.");
                        }

                        const novaBatida = {
                            horario: new Date().toISOString(),
                            localizacao: { latitude, longitude },
                        };

                        if (batidasHoje.length > 0) {
                            const ultimaBatida = new Date(
                                batidasHoje[0].batidas[batidasHoje[0].batidas.length - 1]?.timestamp
                            );
                            const diferencaMilissegundos = new Date(novaBatida.horario) - ultimaBatida;
                            const diferencaHoras = diferencaMilissegundos / (1000 * 60 * 60);

                            if (diferencaHoras < 1) {
                                const confirmar = window.confirm(
                                    "O registro anterior foi feito há menos de uma hora. Deseja registrar outro ponto?"
                                );
                                if (!confirmar) {
                                    setLoading(false);
                                    return;
                                }
                            }
                        }

                        const dadosParaEnviar = {
                            id_rede,
                            empresa,
                            colaborador,
                            id_colaborador,
                            Hora_Extra,
                            Total_de_Horas,
                            Atraso,
                            Processado,
                            Solicitacao,
                            Feriado,
                            Ferias,
                            ponto,
                            data: dataAtual,
                            batidas: [novaBatida],
                        };

                        await enviarBatidas(dadosParaEnviar);
                        alert("Ponto registrado com sucesso!");
                        await carregarBatidas();
                        window.location.reload();
                    } catch (error) {
                        console.error("Erro ao registrar ponto:", error.message);
                        alert("Erro ao registrar ponto. Verifique suas informações e tente novamente.");
                    } finally {
                        setLoading(false);
                    }
                },
                (error) => {
                    console.error("Erro ao obter localização:", error);
                    alert("Erro ao obter localização. Verifique as permissões do navegador.");
                    setLoading(false);
                }
            );
        } else {
            alert("Geolocalização não suportada pelo navegador.");
            setLoading(false);
        }
    };

    const formatarData = (dataISO) => {
        if (!dataISO) return 'Data inválida';
        const data = new Date(dataISO);
        return data.toLocaleDateString('pt-BR');
    };

    const getIconeStatus = (status) => {
        switch (status) {
            case 0: return { name: '⏳', color: 'orange', text: 'Pendente' };
            case 1: return { name: '✅', color: 'green', text: 'Aprovado' };
            case 2: return { name: '❌', color: 'red', text: 'Cancelado' };
            case 3: return { name: '❌', color: 'red', text: 'Rejeitado' };
            default: return { name: '❔', color: 'gray', text: 'Desconhecido' };
        }
    };

    useEffect(() => {
        console.log("tipoSolicitacao atualizado:", tipoSolicitacao);
        console.log("modalRegistro atualizado:", modalRegistro);
    }, [tipoSolicitacao, modalRegistro]);

    const cancelarSolicitacao = async () => {
        try {
            const idRede = await secureStorage.getItem('id_r');
            const solicitacao_id = registroSelecionado._id;

            // Remove o campo _id para não enviá-lo na atualização
            const { _id, ...dadosSemId } = registroSelecionado;

            // Cria o objeto de solicitação contendo todos os dados originais (exceto _id)
            // e atualiza os campos desejados. Atualizamos o status para 2 (Cancelado) e formatamos a data interna.
            const solicitacaoData = {
                ...dadosSemId,
                status: 2, // Atualiza o status para "Cancelado"

            };

            // Chama a API para atualizar a solicitação e captura o retorno
            const response = await Updatesolicitacoes(idRede, solicitacao_id, solicitacaoData);

            if (response.success) {
                // Exibe um alerta e, ao confirmar, fecha o modal
                window.alert(
                    "Sucesso",
                    "Solicitação cancelada com sucesso!",
                    [
                        {
                            text: "OK",
                            onPress: () => {
                                fecharDetalhes();
                            }
                        }
                    ]
                );
                fecharDetalhes();
                window.location.reload();
            } else {
                window.alert("Erro", `Não foi possível cancelar a solicitação. ${response.message || ''}`);
            }
        } catch (error) {
            window.alert("Erro", "Não foi possível cancelar a solicitação.");
            console.error("Erro ao cancelar a solicitação:", error);
        }
    };

    const getStatusTexto = (status) => {
        switch (status) {
            case 0: return 'Aguardando';
            case 1: return 'Aprovado';
            case 2: return 'Cancelado';
            case 3: return 'Rejeitado';
            default: return 'Desconhecido';
        }
    };

    return (
        <S.Container>
            <Header />
            <div style={{
                position: 'fixed',
                top: 80,
                right: 20,
                zIndex: 1000,
                cursor: 'pointer'
            }}>
                <FaQuestionCircle
                    size={28}
                    color="#cf0505"
                    title="Ajuda em vídeo"
                    onClick={() => setMostrarAjuda(true)}
                />
            </div>
            {/* Exibe o modal com o vídeo ao clicar no ícone */}
            <ExibirWiki
                link='https://www.youtube.com/embed/OIeA40ka47M'
                isOpen={mostrarAjuda}
                onClose={() => setMostrarAjuda(false)}
            />
            <S.BackButton onClick={() => navigate('/home')}>
                <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
                Voltar
            </S.BackButton>

            <S.MainCard>
                <h2>Ponto Eletrônico</h2>
                <p>
                    <strong>Data Atual:</strong> {new Date().toLocaleDateString("pt-BR")}
                </p>
                <p>
                    <strong>Horas Trabalhadas Hoje:</strong> {horasHoje}
                </p>

                {batidasHoje.length > 0 ? (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h4>Entrada</h4>
                            {batidasHoje[0].batidas
                                .filter((_, i) => i % 2 === 0)
                                .map((batida, index) => (
                                    <p key={index}>
                                        {new Date(batida.timestamp).toLocaleTimeString("pt-BR", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        })}
                                    </p>
                                ))}
                        </div>
                        <div>
                            <h4>Saída</h4>
                            {batidasHoje[0].batidas
                                .filter((_, i) => i % 2 !== 0)
                                .map((batida, index) => (
                                    <p key={index}>
                                        {new Date(batida.timestamp).toLocaleTimeString("pt-BR", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        })}
                                    </p>
                                ))}
                        </div>
                    </div>
                ) : (
                    <p>Nenhum registro para hoje.</p>
                )}
            </S.MainCard>

            <S.Button onClick={handleRegistrarPonto} disabled={loading}>
                {loading ? "Registrando..." : "Registrar Ponto"}
            </S.Button>

            <S.InfoCardsContainer>
                <S.InfoCard>
                    <h3>Horas Trabalhadas</h3>
                    <p>{horasTrabalhadas}</p>
                </S.InfoCard>
                <S.InfoCard>
                    <h3>Total Atrasos</h3>
                    <p>{totalAtrasos}</p>
                </S.InfoCard>
                <S.InfoCard>
                    <h3>Saldo Banco de Horas</h3>
                    <p>{saldoBancoHoras}</p>
                </S.InfoCard>
            </S.InfoCardsContainer>

            {/* Abas */}
            <S.TabContainer>
                <S.TabButton
                    active={selectedTab === "registros"}
                    onClick={() => setSelectedTab("registros")}
                >
                    Registros
                </S.TabButton>
                <S.TabButton
                    active={selectedTab === "solicitacoes"}
                    onClick={() => setSelectedTab("solicitacoes")}
                >
                    Solicitações
                </S.TabButton>
            </S.TabContainer>
            {/* Conteúdo das abas */}
            {selectedTab === "registros" && (
                loading ? (
                    <p>Carregando registros...</p>
                ) : (
                    registros.length > 0 ? (
                        registros.map((registro, index) => {
                            const dataRegistro = new Date(`${registro.data}T00:00:00-03:00`);
                            return (
                                <S.Card key={index} onClick={() => setModalRegistro(registro)}>
                                    <div className="card-header">
                                        <h4>{`Data: ${dataRegistro.toLocaleDateString("pt-BR")} (${registro.dia_da_semana || ''})`}</h4>
                                        <p>
                                            <strong>Horas Trabalhadas:</strong> {registro.horas_trabalhadas || "00:00"}
                                        </p>
                                    </div>
                                </S.Card>
                            );
                        })
                    ) : (
                        <p>Nenhum registro encontrado.</p>
                    )
                )
            )}

            {selectedTab === "solicitacoes" && (
                solicitacoes.length > 0 ? (
                    [...solicitacoes] // Criando uma cópia para não modificar o array original
                        .sort((a, b) => new Date(b.data_criacao) - new Date(a.data_criacao)) // Ordenação decrescente
                        .map((solicitacao, index) => {
                            const icone = getIconeStatus(solicitacao.status);
                            return (
                                <S.Card key={index} onClick={() => setRegistroSelecionado(solicitacao)}>
                                    <div className="card-header">
                                        <h4>{`Solicitação de Ajuste`}</h4>
                                        <p style={{ color: icone.color }}>
                                            {icone.name} {icone.text}
                                        </p>
                                    </div>
                                    <div className="card-body">
                                        <p><strong>Data Solicitação:</strong> {formatarData(solicitacao.data_criacao)}</p>
                                        <p><strong>Motivo:</strong> {solicitacao.motivo}</p>
                                        <p><strong>Data do Ajuste:</strong> {formatarData(solicitacao.registro.data)}</p>
                                        <p><strong>Descrição:</strong> {solicitacao.descricao}</p>
                                    </div>
                                </S.Card>
                            );
                        })
                ) : (
                    <p>Nenhuma solicitação para exibir.</p>
                )
            )}
            {registroSelecionado && !tipoSolicitacao && (
                <S.ModalOverlay onClick={fecharDetalhes}>
                    <S.ModalContent onClick={(e) => e.stopPropagation()}>
                        {/* Botão para fechar o modal */}
                        <S.ButtonFechar onClick={fecharDetalhes}>
                            <MdClose size={24} color="black" />
                        </S.ButtonFechar>

                        <h3 style={{ textAlign: 'center', marginBottom: '15px' }}>
                            Detalhes da Solicitação
                        </h3>

                        <div style={{ padding: '0 20px' }}>
                            <p>
                                <strong>Data Solicitação:</strong> {formatarData(registroSelecionado.data_criacao)}
                            </p>
                            <p>
                                <strong>Registros:</strong>{" "}
                                {(registroSelecionado.registros_ajuste || []).map((hora, index) => (
                                    <span key={index}>• {hora} </span>
                                ))}
                            </p>
                            <p>
                                <strong>Motivo:</strong> {registroSelecionado.motivo}
                            </p>
                            <p>
                                <strong>Data do Ajuste:</strong> {formatarData(registroSelecionado.registro.data)}
                            </p>
                            <p>
                                <strong>Descrição:</strong> {registroSelecionado.descricao}
                            </p>
                            <p>
                                <strong>Status:</strong> {getStatusTexto(registroSelecionado.status)}
                            </p>

                            {/* Botão Cancelar (aparece apenas se o status for "Aguardando", por exemplo, status === 0) */}
                            {registroSelecionado.status === 0 && (
                                <button onClick={cancelarSolicitacao} style={{ backgroundColor: "red", color: "white" }}>
                                    Cancelar
                                </button>
                            )}
                        </div>
                    </S.ModalContent>
                </S.ModalOverlay>
            )}
            {modalRegistro && (
                <S.ModalOverlay onClick={() => setModalRegistro(false)}>
                    <S.ModalContent onClick={(e) => e.stopPropagation()}>
                        <h3>Detalhes do Registro</h3>
                        <p>Data: {new Date(modalRegistro.data).toLocaleDateString("pt-BR")}</p>
                        <p>Horas Trabalhadas: {modalRegistro.horas_trabalhadas || "00:00"}</p>
                        <h4>Batidas:</h4>
                        <div style={{ display: "flex", justifyContent: "space-around", gap: "30px" }}>
                            <div style={{ textAlign: "center" }}>
                                <h4>Entrada</h4>
                                {modalRegistro.batidas
                                    .filter((_, i) => i % 2 === 0)
                                    .map((batida, index) => (
                                        <p key={`entrada-${index}`}>
                                            {new Date(batida.timestamp).toLocaleTimeString("pt-BR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })}
                                        </p>
                                    ))}
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <h4>Saída</h4>
                                {modalRegistro.batidas
                                    .filter((_, i) => i % 2 !== 0)
                                    .map((batida, index) => (
                                        <p key={`saida-${index}`}>
                                            {new Date(batida.timestamp).toLocaleTimeString("pt-BR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })}
                                        </p>
                                    ))}
                            </div>
                        </div>
                        <button onClick={() => setModalRegistro(false)} style={{ marginRight: '10px' }}>
                            Fechar
                        </button>
                        <button onClick={async () => {
                            await setTipoSolicitacao('ajuste');
                            setRegistrosselecionado(modalRegistro);
                            setModalRegistro(false); // Fecha o modal ao abrir a solicitação
                        }}>Solicitar Ajuste</button>
                    </S.ModalContent>
                </S.ModalOverlay>
            )}

            {/* Tela de Solicitação de Ponto */}
            {registrosselecionado && tipoSolicitacao && (
                console.log("Renderizando SolicitacaoPonto..."),
                <SolicitacaoPonto
                    registro={registrosselecionado}
                    tipoSolicitacao={tipoSolicitacao}
                    isVisible={true}
                    onClose={fecharDetalhes}
                />
            )}
            <Footer />
        </S.Container>
    );

}

export default Ponto;
