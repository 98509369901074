import React, { useState, useEffect, useCallback } from "react";
import { format, parseISO, isSameDay } from "date-fns";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import secureStorage from '../../utils/SecureStorage';
import { MdArrowBack } from 'react-icons/md';

// Nossos Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import iconDefault from "../../assets/Default.png";
import iconIniciado from "../../assets/Iniciado.png";
import iconTerminado from "../../assets/Terminado.png";
import iconAtrasada from "../../assets/Atrasada.png";
import VisitaServicesBusca from "../../services/Visita/VisitaServicesBusca";
import ExibirWiki from '../../components/ExibirWiki/ExibirWiki';
import { FaQuestionCircle } from 'react-icons/fa';

const statusEnum = {
  0: "Nao iniciado",
  1: "Iniciado",
  2: "Concluido",
  3: "Atrasado"
};

const FilterCard = ({ title, actived }) => {
  return (
    <S.FilterCardContainer actived={actived.toString()}>
      <span>{title}</span>
    </S.FilterCardContainer>
  );
};

const Quadrodeatendimentos = () => {
  const [filterActived, setFilterActived] = useState("today");
  const [visitas, setVisitas] = useState([]);
  const [idrede] = useState(secureStorage.getItem("id_r"));
  const [name, setName] = useState("");
  const [name2, setName2] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const navigate = useNavigate();
  const [mostrarAjuda, setMostrarAjuda] = useState(false);

  const loadVisitas = useCallback(async () => {
    try {
      const data = await VisitaServicesBusca.loadregistros(idrede, filterActived);
      setVisitas(data);
    } catch (error) {
      console.error("Erro ao buscar atendimentos:", error);
      alert("Erro ao buscar atendimentos. Veja o console para mais detalhes.");
    }
  }, [idrede, filterActived]);

  useEffect(() => {
    loadVisitas();
  }, [loadVisitas]);

  const filterColaborador = (e) => {
    const keyword = e.target.value;
    setName(keyword);
    if (keyword !== "") {
      const results = visitas.filter((user) => user.colaborador.nome.toLowerCase().startsWith(keyword.toLowerCase()));
      setVisitas(results);
    } else {
      loadVisitas();
    }
  };

  const filterEstabelecimento = (e) => {
    const keyword = e.target.value;
    setName2(keyword);
    if (keyword !== "") {
      const results = visitas.filter((user) => user.estabelecimento.nome_fantasia.toLowerCase().startsWith(keyword.toLowerCase()));
      setVisitas(results);
    } else {
      loadVisitas();
    }
  };

  const filterByDate = (e) => {
    const selected = e.target.value;
    setSelectedDate(selected);
    if (selected !== "") {
      const results = visitas.filter((user) => {
        const dataVisita = parseISO(user.data.$date.split('T')[0]);
        const dataFiltro = parseISO(selected);
        return isSameDay(dataVisita, dataFiltro);
      });
      setVisitas(results);
    } else {
      loadVisitas();
    }
  };

  const formatDate = (dateObj) => {
    const date = parseISO(dateObj.$date.split('T')[0]);
    return format(date, "dd-MM-yyyy");
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "0":
      case 0:
        return iconDefault;
      case "1":
      case 1:
        return iconIniciado;
      case "2":
      case 2:
        return iconTerminado;
      case "3":
      case 3:
        return iconAtrasada;
      default:
        return iconDefault;
    }
  };

  const handleEditClick = (id) => {
    navigate(`/Atendimento/${id}/editar`);
  };

  const handleViewClick = (id) => {
    navigate(`/Atendimento/${id}`);
  };

  return (
    <S.Container>
      <Header />
      {/* <div style={{ position: 'fixed', top: 80, right: 20, zIndex: 1000, cursor: 'pointer' }}>
        <FaQuestionCircle size={28} color="#cf0505" title="Ajuda em vídeo" onClick={() => setMostrarAjuda(true)} />
      </div>
      <ExibirWiki link='https://www.youtube.com/embed/ajqFTS60cyU' isOpen={mostrarAjuda} onClose={() => setMostrarAjuda(false)} /> */}

      <S.BackButton onClick={() => navigate('/home')}>
        <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
        Voltar
      </S.BackButton>

      <S.FilterArea>
        <button type="button" onClick={() => setFilterActived("today")}> <FilterCard title="Hoje" actived={filterActived === "today"} /> </button>
        <button type="button" onClick={() => setFilterActived("week")}> <FilterCard title="Semana" actived={filterActived === "week"} /> </button>
        <button type="button" onClick={() => setFilterActived("month")}> <FilterCard title="Mês" actived={filterActived === "month"} /> </button>
        <button type="button" onClick={() => setFilterActived("year")}> <FilterCard title="Ano" actived={filterActived === "year"} /> </button>
      </S.FilterArea>

      <S.Title>
        <h3>{filterActived === "late" ? "ATENDIMENTOS ATRASADOS" : "ATENDIMENTOS"}</h3>
      </S.Title>

      <S.Title2>
        <a href="#CF0505">{"NÃO INICIADO"}</a><img src={iconDefault} alt="Nao iniciado" />
        <a href="#CF0505">{"INICIADO"}</a><img src={iconIniciado} alt="Iniciado" />
        <a href="#CF0505">{"CONCLUÍDO"}</a><img src={iconTerminado} alt="Concluído" />
        <a href="#CF0505">{"ATRASADO"}</a><img src={iconAtrasada} alt="Atrasado" />
      </S.Title2>

      <S.Content2>
        <input type="search" value={name} onChange={filterColaborador} className="input" placeholder="Colaborador" />
        <input type="search" value={name2} onChange={filterEstabelecimento} className="input" placeholder="Estabelecimento" />
        <input type="date" value={selectedDate} onChange={filterByDate} className="input" placeholder="Data" />
      </S.Content2>

      <S.StatusContainer>
        {[0, 1, 2, 3].map((statusKey) => (
          <S.StatusColumn key={statusKey}>
            <S.StatusTitle>{statusEnum[statusKey]}</S.StatusTitle>
            {visitas
              .filter((v) => parseInt(v.status) === statusKey)
              .sort((a, b) => parseISO(b.data.$date) - parseISO(a.data.$date))
              .map((t, index) => {
                const formattedDate = formatDate(t.data);
                return (
                  <S.Card key={`${t._id.$oid}-${index}`}>
                    <S.VisitaCardTitle>{`Tarefa: ${t.descricao_visita}`}</S.VisitaCardTitle>
                    <S.VisitaCardTitle>{t.estabelecimento.nome_fantasia}</S.VisitaCardTitle>
                    <S.VisitaCardParagraph>Colaborador: {t.colaborador.nome}</S.VisitaCardParagraph>
                    <S.VisitaCardParagraph>
                      Cliente: {t.clientes.map((cliente, i) => (
                        <span key={i}>{cliente.nome}{i < t.clientes.length - 1 ? ' | ' : ''}</span>
                      ))}
                    </S.VisitaCardParagraph>
                    <S.VisitaCardParagraph>Status: <S.VisitaCardIcon src={getStatusIcon(parseInt(t.status))} alt={statusEnum[t.status]} /></S.VisitaCardParagraph>
                    <S.VisitaCardParagraph>Data: {formattedDate}</S.VisitaCardParagraph>
                    <S.ButtonsContainer>
                      {t.status !== "2" && secureStorage.getItem("TipoCockpit") !== 1 && (
                        <S.EditButton onClick={() => handleEditClick(t._id.$oid)}>Editar</S.EditButton>
                      )}
                      <S.ViewButton onClick={() => handleViewClick(t._id.$oid)}>Visualizar</S.ViewButton>
                    </S.ButtonsContainer>
                  </S.Card>
                );
              })}
          </S.StatusColumn>
        ))}
      </S.StatusContainer>

      <Footer />
    </S.Container>
  );
};

export default Quadrodeatendimentos;