import React, { useState, useEffect } from "react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import Modal from "react-modal";
import * as S from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import colaborador from "../../assets/collaborator.png";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import BuscaPonto from '../../services/ponto/BuscaPontoPordia';
import secureStorage from '../../utils/SecureStorage';
import ExibirWiki from '../../components/ExibirWiki/ExibirWiki';
import { FaQuestionCircle } from 'react-icons/fa';

const defaultIcon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
  shadowSize: [41, 41],
});

const AcompanhamentoPonto = () => {
  const [visitas, setVisitas] = useState([]);
  const [selectedDate, setSelectedDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentAddress, setCurrentAddress] = useState("");
  const navigate = useNavigate();
  const { loadregistros } = BuscaPonto([]);
  const [mostrarAjuda, setMostrarAjuda] = useState(false);
  const loadregistrosponto = async () => {
    const id_rede = secureStorage.getItem("id_r");
    const data = selectedDate; // Data selecionada no estado
  
    if (!id_rede || !data) {
      console.error("ID da Rede ou Data não fornecidos.");
      return;
    }
  
    console.log(`Buscando registros para id_rede: ${id_rede} e data: ${data}`);
    const error = await loadregistros(id_rede, data);
  
    if (error) {
      console.error("Erro ao carregar registros:", error);
      return;
    }
  
    const dataUser = secureStorage.getItem("dataponto");
    if (dataUser) {
      const registros = JSON.parse(dataUser);
      const colaboradores = registros.map((registro) => ({
        colaborador: registro.colaborador,
        foto: colaborador, // Imagem padrão por enquanto
        data: registro.data,
        dia_da_semana: registro.dia_da_semana,
        batidas: registro.batidas || [], // Garante que 'batidas' existe
      }));
  
      setVisitas(colaboradores); // Atualiza o estado com os colaboradores e batidas
      console.log("Dados carregados com sucesso:", colaboradores);
    } else {
      console.warn("Nenhum dado encontrado no storage.");
      setVisitas([]);
    }
  };
  
  useEffect(() => {
    loadregistrosponto();
  }, [selectedDate]); // Recarrega ao mudar a data
  
  

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const applyDateFilter = () => {
    loadregistrosponto();
  };
  

  const fetchAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get("https://nominatim.openstreetmap.org/reverse", {
        params: {
          lat: latitude,
          lon: longitude,
          format: "json",
        },
      });
      const data = response.data;
      setCurrentAddress(data.display_name || "Endereço não encontrado");
    } catch (error) {
      console.error("Erro ao buscar o endereço:", error);
      setCurrentAddress("Erro ao carregar endereço");
    }
  };

  const openMapModal = (location) => {
    if (location.latitude && location.longitude) {
      setCurrentLocation(location);
      fetchAddress(location.latitude, location.longitude);
      setModalIsOpen(true);
    } else {
      alert("Localização inválida. Não é possível abrir o mapa.");
    }
  };

  const closeMapModal = () => {
    setModalIsOpen(false);
    setCurrentLocation(null);
    setCurrentAddress("");
  };

  const formatDate = (dateStr) => {
    const date = parseISO(dateStr.split("T")[0]);
    return format(date, "dd/MM/yyyy", { locale: ptBR });
  };

  const formatWeekDay = (dateStr) => {
    const date = parseISO(dateStr.split("T")[0]);
    return format(date, "EEEE", { locale: ptBR }).toUpperCase();
  };

  const renderBatidasInPairs = (batidas) => {
    const pairs = [];
    for (let i = 0; i < batidas.length; i += 2) {
      pairs.push(
        <div
          key={i}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div>
            {batidas[i] && (
              <>
                <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                  {format(parseISO(batidas[i].timestamp), "HH:mm")}
                </span>
                <FaMapMarkerAlt
                  style={{ fontSize: "18px", color: "#007BFF", cursor: "pointer" }}
                  onClick={() => openMapModal(batidas[i].localizacao)}
                />
              </>
            )}
          </div>
          <div>
            {batidas[i + 1] && (
              <>
                <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                  {format(parseISO(batidas[i + 1].timestamp), "HH:mm")}
                </span>
                <FaMapMarkerAlt
                  style={{ fontSize: "18px", color: "#007BFF", cursor: "pointer" }}
                  onClick={() => openMapModal(batidas[i + 1].localizacao)}
                />
              </>
            )}
          </div>
        </div>
      );
    }
    return pairs;
  };

  return (
    <S.Container>
      <Header />
     {/*  <div style={{
        position: 'fixed',
        top: 80,
        right: 20,
        zIndex: 1000,
        cursor: 'pointer'
      }}>
        <FaQuestionCircle
          size={28}
          color="#cf0505"
          title="Ajuda em vídeo"
          onClick={() => setMostrarAjuda(true)}
        />
      </div> */}
      {/* Exibe o modal com o vídeo ao clicar no ícone */}
     {/*  <ExibirWiki
        link='https://www.youtube.com/embed/ajqFTS60cyU'
        isOpen={mostrarAjuda}
        onClose={() => setMostrarAjuda(false)}
      /> */}
      <S.BackButton onClick={() => navigate('/home')}>
        <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
        Voltar
      </S.BackButton>
      <S.BackButton onClick={() => navigate("/home")}>
        <MdArrowBack style={{ marginRight: "8px", fontSize: "20px" }} />
        Voltar
      </S.BackButton>

      <S.Title>
        <h3>Acompanhamento Ponto</h3>
      </S.Title>

      <S.FilterArea>
        <input
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className="input"
        />
        
      </S.FilterArea>

      <S.Content>
        {visitas.map((t, index) => (
          <S.VisitaCard key={`${t._id}-${index}`}>
            <S.VisitaCardTitle>{t.colaborador}</S.VisitaCardTitle>
            <img
              src={t.foto}
              alt="Foto do Colaborador"
              style={{ width: "100px", height: "100px", borderRadius: "50%" }}
            />
            <S.VisitaCardParagraph>
              Data: {formatDate(t.data)} - {formatWeekDay(t.data)}
            </S.VisitaCardParagraph>
            <S.VisitaCardParagraph>Registros:</S.VisitaCardParagraph>
            {renderBatidasInPairs(t.batidas)}
          </S.VisitaCard>
        ))}
      </S.Content>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeMapModal}
        contentLabel="Mapa Completo"
        style={{
          overlay: {
            zIndex: 1000,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "80%",
            padding: "0",
          },
        }}
      >
        {currentLocation && (
          <div style={{ position: "relative", height: "100%" }}>
            <div
              style={{
                position: "absolute",
                top: 10,
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                padding: "10px",
                borderRadius: "8px",
                zIndex: 1000,
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
                width: "80%",
              }}
            >
              <strong>Endereço:</strong> {currentAddress}
            </div>
            <MapContainer
              center={[currentLocation.latitude, currentLocation.longitude]}
              zoom={16}
              style={{
                height: "calc(100% - 50px)",
                marginTop: "50px",
              }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[currentLocation.latitude, currentLocation.longitude]}
                icon={defaultIcon}
              >
                <Popup>
                  Localização: {currentLocation.latitude}, {currentLocation.longitude}
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        )}
        <button
          onClick={closeMapModal}
          style={{
            position: "absolute",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
            padding: "10px 20px",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Fechar Mapa
        </button>
      </Modal>

      <Footer />
    </S.Container>
  );
};

export default AcompanhamentoPonto;