import React from 'react';
import * as S from './styles';
import logo from '../../assets/logo.png';
import { Link, useLocation } from 'react-router-dom';
function Header_login() {
  const location = useLocation();

  return (
    <S.Container>      
      <S.LeftSide>
        <img src={logo} alt="Logo" width="180" height="80"/>
      </S.LeftSide>
      <S.RightSide>
        {/* <Link to="/PoliticadePrivacidade">Política de Privacidade</Link> */}
        <Link to="/">Sobre o MarketDo</Link>
        <Link to="/FaleConosco">Fale Conosco</Link>
        {/* <Link to="/Planos">Planos e Preços</Link> */}
        {location.pathname !== "/Login" && ( // Exibe o link "Login" apenas se não estiver na rota de login
          <Link to="/Login">Entrar no MarketDo</Link>
        )}
      </S.RightSide>
    </S.Container>
  );
}

export default Header_login;
