import React, { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import logo from '../../assets/lOGIN.png';
import useBuscaColaborador from '../../services/Colaborador/BuscaClolaborador';
import BuscaEmpreCnpj from '../../services/Colaborador/BuscaEmpreCnpj';
import secureStorage from '../../utils/SecureStorage';

const generateRandomID = () => Math.random().toString(36).substring(2, 8).toUpperCase();

const GeraPdfPonto = ({ colaboradorAtivo }) => {
    const [logoBase64, setLogoBase64] = useState(null);
    const { loadRegistros, loading } = useBuscaColaborador();
    const { loadregistros4 } = BuscaEmpreCnpj([]);

    useEffect(() => {
        const loadImageAsBase64 = async () => {
            const canvas = document.createElement('canvas');
            const img = new Image();
            img.src = logo;
            await new Promise((resolve) => {
                img.onload = resolve;
            });
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            setLogoBase64(canvas.toDataURL('image/png'));
        };
        loadImageAsBase64();
    }, []);

    if (!colaboradorAtivo || !colaboradorAtivo.registros || !logoBase64) return null;

    const loadregistrosredecnpj = async () => {
        const id_rede = secureStorage.getItem("id_r");
        if (id_rede) {
            const error = await loadregistros4(id_rede);
            if (error) {
                console.error("Erro ao carregar registros:", error);
            } else if (secureStorage.getItem("dataredecnpj")) {
                let dataUser = secureStorage.getItem("dataredecnpj");
                if (dataUser) {
                    dataUser = JSON.parse(dataUser);
                    return dataUser.length ? dataUser[0] : null;
                }
            }
        }
        return null;
    };

    const exportToPdf = async () => {
        if (!colaboradorAtivo || !colaboradorAtivo.id_colaborador) {
            alert("Colaborador ativo não definido ou ID de colaborador não encontrado.");
            return;
        }

        try {
            let empresaDados = await loadregistrosredecnpj();
            await loadRegistros(colaboradorAtivo.id_colaborador, (dadosColaborador) => {
                if (!dadosColaborador) {
                    alert("Erro ao buscar dados do colaborador.");
                    return;
                }
                generatePdf(dadosColaborador, empresaDados);
            });
        } catch (error) {
            console.error("Erro ao gerar PDF:", error);
        }
    };

    const generatePdf = (colaboradorDados, empresaDados) => {
        const doc = new jsPDF();
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const timestamp = `${day}-${month}-${year} ${hours}:${minutes}`;
        const randomID = generateRandomID();

        const identifier = `${colaboradorAtivo.colaborador.replace(/\s+/g, '_')}_${month}-${year}_${timestamp}_${randomID}`;
        const fileName = `${identifier}.pdf`;

        doc.addImage(logoBase64, 'PNG', 10, 5, 50, 15);

        const title = `Espelho de Ponto - ${colaboradorAtivo.colaborador || 'Colaborador'}_${month}-${year}`;
        doc.setFontSize(16);
        const pageWidth = doc.internal.pageSize.width;
        const textWidth = doc.getTextWidth(title);
        doc.text(title, (pageWidth - textWidth) / 2, 30);

        const resumoEmpresa = [
            ['Nome Fantasia', empresaDados?.nome_fantasia || 'N/A'],
            ['Razão Social', empresaDados?.razao_social || 'N/A'],
            ['CNPJ da Empresa', empresaDados?.cpf_cnpj || 'N/A'],
            ['Endereço da Empresa', empresaDados?.endereco || 'N/A'],
        ];

        doc.autoTable({
            startY: 40,
            head: [['Campo', 'Informação']],
            body: resumoEmpresa,
            theme: 'grid',
            styles: { halign: 'center', valign: 'middle', fontStyle: 'normal' },
            headStyles: { fillColor: [255, 0, 0], textColor: [0, 0, 0], halign: 'center' },
        });

        const resumoColaborador = [
            ['Nome Completo', `${colaboradorDados.nome || 'undefined'} ${colaboradorDados.sobrenome || ''}`.trim()],
            ['CPF/CNPJ', colaboradorDados.cpf_cnpj || 'N/A'],
            ['Número de Registro', colaboradorDados.dadosprofissionais?.numero_registro || '0000'],
            ['Cargo', colaboradorDados.dadosprofissionais?.cargo || 'N/A'],
            ['Departamento', colaboradorDados.dadosprofissionais?.departamento || 'N/A'],
        ];

        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 10,
            head: [['Campo', 'Informação']],
            body: resumoColaborador,
            theme: 'grid',
            styles: { halign: 'center', valign: 'middle', fontStyle: 'normal' },
            headStyles: { fillColor: [255, 0, 0], textColor: [0, 0, 0], halign: 'center' },
        });

        const resumoPonto = [
            ['Total de horas', colaboradorAtivo.total_horas_trabalhadas || '00:00'],
            ['Horas Extras', colaboradorAtivo.total_horas_extras || '00:00'],
            ['Atraso', colaboradorAtivo.total_atrasos || '00:00'],
            ['Banco Horas', colaboradorAtivo.saldo_banco_horas || '00:00'],
        ];

        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 10,
            head: [['Campo', 'Informação']],
            body: resumoPonto,
            theme: 'grid',
            styles: { halign: 'center', valign: 'middle', fontStyle: 'normal' },
            headStyles: { fillColor: [255, 0, 0], textColor: [0, 0, 0], halign: 'center' },
        });

        const maxBatidas = colaboradorAtivo.registros.reduce(
            (max, reg) => Math.max(max, reg.batidas.length), 0
        );

        const pontoHeaders = [
            'Data', 'Dia da Semana',
            ...Array.from({ length: maxBatidas }, (_, i) => `${i + 1}º Registro`),
            'Total de Horas', 'Horas Extras', 'Atraso'
        ];

        const data = colaboradorAtivo.registros.map((registro) => {
            const dataFormatada = formatData(registro.data);
            const diaSemana = getDiaDaSemana(registro.data);

            let batidas = [];
            if (registro.Feriado) {
                batidas = [registro.observacaoFeriado || 'Feriado'];
            } else if (registro.Ferias) {
                batidas = ['Férias'];
            } else if (registro.Atestado) {
                batidas = [registro.observacaoAtestado || 'Atestado'];
            } else if (registro.Falta) {
                batidas = [registro.observacaoFalta || 'Falta'];    
            } else if (registro.sem_ocorrencia || registro.Semocorencia) {
                batidas = ['Sem Ocorrência'];
            } else {
                batidas = registro.batidas.map((batida) =>
                    new Date(batida.timestamp).toLocaleTimeString()
                );
            }

            while (batidas.length < maxBatidas) {
                batidas.push('Sem registro');
            }

            return [
                dataFormatada,
                diaSemana,
                ...batidas,
                registro.Total_de_Horas || '00:00',
                registro.Hora_Extra || '00:00',
                registro.Atraso || '00:00'
            ];
        });

        doc.autoTable({
            head: [pontoHeaders],
            body: data,
            startY: doc.previousAutoTable.finalY + 10,
            theme: 'grid',
            styles: {
                halign: 'center',
                valign: 'middle',
                fontSize: colaboradorAtivo.registros.length > 15 ? 8 : 10,
                fontStyle: 'normal',
            },
            headStyles: {
                fillColor: [255, 0, 0],
                textColor: [0, 0, 0],
            },
        });

        const totalPages = doc.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            doc.setFontSize(100);
            doc.setTextColor(150, 150, 150);
            doc.setFont("helvetica", "bold");
            doc.setGState(new doc.GState({ opacity: 0.1 }));
            doc.text("marketdo", pageWidth / 2, doc.internal.pageSize.height / 2, {
                align: 'center',
                angle: 30,
            });
            if (empresaDados?.nome_fantasia) {
                doc.setFontSize(60);
                doc.text(empresaDados.nome_fantasia, pageWidth / 2, (doc.internal.pageSize.height * 3) / 4, {
                    align: 'center',
                    angle: 30,
                });
            }
            doc.setGState(new doc.GState({ opacity: 1 }));
            const footerText = `Gerado por MarketDo - ${timestamp} | Página ${i} de ${totalPages} | ID: ${randomID}`;
            doc.setFontSize(10);
            doc.setTextColor(0, 0, 0);
            doc.text(footerText, 10, doc.internal.pageSize.height - 10);
        }

        doc.save(fileName);
    };

    return (
        <button onClick={exportToPdf} disabled={loading}>
            {loading ? 'Gerando PDF...' : 'Exportar PDF'}
        </button>
    );
};

const getDiaDaSemana = (data) => {
    const diasDaSemana = [
        'Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira',
        'Quinta-feira', 'Sexta-feira', 'Sábado'
    ];
    const date = new Date(data + 'T00:00:00');
    return diasDaSemana[date.getDay()] || '-';
};

const formatData = (data) => {
    const date = new Date(data + 'T00:00:00');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

export default GeraPdfPonto;
