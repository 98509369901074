import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;  
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FilterArea = styled.div`
  display: flex;
  justify-content: center;  
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 10px;

  button {
    background: none;
    border: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FilterCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 50px;
  background: ${props => props.actived === 'true' ? '#B22222' : '#20295F'};
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  color: white;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const Title = styled.div`
  width: 100%;
  text-align: center;
  margin: 40px 0 20px;
  font-size: 22px;
  font-weight: bold;
  color: #20295F;
  border-bottom: 2px solid #FF0000;
  padding-bottom: 10px;
`;

export const Title2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;

  img {
    width: 24px;
    height: 24px;
    margin: 0 5px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 40px;
  margin-bottom: 50px;
`;

export const Content2 = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
`;

export const VisitaCard = styled.div`
  width: 300px;
  background: ${props => {
    switch (props.type) {
      case 0:
        return "#FFF8E1";
      case 1:
        return "#BBDEFB";
      case 2:
        return "#C8E6C9";
      case 3:
        return "#FFCDD2";
      default:
        return "#FFF";
    }
  }};
  border: 1px solid #20295F;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  text-align: left;
`;

export const Footer = styled.div`
  width: 100%;
  text-align: center;
  background: #FFF;
  padding: 10px;
  border-top: 2px solid #FF0000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const VisitaCardTitle = styled.h4`
  margin: 5px 0;
  color: #20295F;
  font-size: 16px;
  font-weight: bold;
`;

export const VisitaCardParagraph = styled.p`
  margin: 5px 0;
  color: #20295F;
  font-size: 14px;
`;

export const VisitaCardIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
`;

export const EditButton = styled.button`
  background-color: #FFA500;
  color: #FFF;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #FF8C00;
  }
`;

export const ViewButton = styled.button`
  background-color: #4CAF50;
  color: #FFF;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #45A049;
  }
`;

export const BackButton = styled.button`
  position: absolute;
  top: 70px;
  left: 20px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #cf0505;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: #800000;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
  flex-wrap: wrap;
`;

export const StatusColumn = styled.div`
  flex: 1;
  min-width: 250px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
`;

export const StatusTitle = styled.h3`
  text-align: center;
  color: #cf0505;
  margin-bottom: 10px;
`;

export const Card = styled.div`
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
  text-align: left;
`;