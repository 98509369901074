import React from 'react';

const ModalWiki = ({ isOpen, onClose, wikiPageUrl }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
      }}
    >
      <div
        style={{
          background: '#fff',
          padding: '1rem',
          borderRadius: '10px',
          width: '100%',
          maxWidth: '1200px',
          height: '90vh',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            border: 'none',
            background: 'transparent',
            fontSize: '24px',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        >
          ✖
        </button>
        <h2 style={{ marginBottom: '10px', color: '#cf0505' }}>Ajuda</h2>

        <div style={{ flex: 1 }}>
          <iframe
            src={wikiPageUrl}
            title="Wiki ou Vídeo"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              borderRadius: '6px',
            }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

export default ModalWiki;
