import { useState } from 'react';
import api from '../api';
import secureStorage from '../../utils/SecureStorage';


const Updatesolicitacoes = async (idRede, solicitacao_id, solicitacaoData) => {
  const apiUrl = process.env.REACT_APP_API_BASE_65;

  try {
    const response = await api.put(`${apiUrl}${idRede}/${solicitacao_id}`, solicitacaoData);
    // Se a resposta estiver OK, retorne um objeto indicando sucesso
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      return { success: false, message: 'Falha na atualização da solicitação' };
    }
  } catch (error) {
    return { success: false, error: error };
  }

};
export default Updatesolicitacoes;

