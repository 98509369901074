import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const SalvarSolicitacao = async (idRede, solicitacao) => {
  const apiUrl = process.env.REACT_APP_API_BASE_65;

  
    console.log("Tentando salvar solicitação...");
    console.log("ID Rede:", idRede);
    console.log("Solicitação:", solicitacao); // Exibe o conteúdo da solicitação para verificar se está correto
    
    try {
      const response = await api.post(`${apiUrl}${idRede}`, solicitacao);
      console.log("Resposta da API recebida:", response);

      // Verifica se a resposta foi bem-sucedida
      if (response.status === 200 || response.status === 201) {
        console.log("Solicitação enviada com sucesso:", response.data);
        return { status: response.status, data: response.data }; // Retorna status e dados da resposta
      } else {
        console.error(`Falha no envio. Status: ${response.status}`);
        throw new Error(`Falha no envio. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Erro ao enviar solicitação:', error);
      
      if (error.response) {
        // Se o erro for de resposta da API, exibe o erro detalhado
        console.error('Erro da API:', error.response.data);
        throw new Error(error.response.data.message || 'Erro desconhecido ao enviar solicitação');
      } else {
        // Caso não tenha uma resposta de erro da API
        console.error('Erro desconhecido (sem resposta da API)');
        throw new Error('Erro desconhecido ao enviar solicitação');
      }
    }
  
  
};

export default SalvarSolicitacao;
